import _ from 'lodash';
import {
  getSite,
  getSites
} from "../Axios/Axios"

function formatSiteUrl(site) {
  if (site) {
    return site.split(':')[1].substr(2);
  }
}

function getSiteHelper(obj, props, siteId) {
  console.log("ViewProps", obj, props, siteId, obj.props);
  if (Object.keys(obj.state.site).length === 0) {
    getSite(siteId ? siteId : props.match.params.id, (status, response) => {
      if (status === "success") {
        console.log(response);
        obj.setState({
          site: response.site,
        });
      } else {}
    });
  }
}

function getSitesHelper(obj, props) {
  console.log("ViewProps", obj, props);
  if (Object.keys(obj.state.activeSites).length === 0) {
    getSites((status, response) => {
      if (status === "success") {
        console.log(response);
        obj.setState({
          activeSites: response.sites.filter((site) => {
            return site.pixel_active === "1";
          }),
        });
      } else {}
    });
  }
}

export {
  formatSiteUrl,
  getSiteHelper,
  getSitesHelper,
}