import React, {Component} from 'react';
import Header from '../common/Header';
import Footer from '../common/Footer';
// import AdminMenu from '../admin/AdminMenu'
import { Link, Redirect } from 'react-router-dom';
// import { isAuthorized } from '../Utilities/Auth';
import $ from 'jquery';


class Dashboard extends Component{

    constructor(props){
        super(props)
       
    }

    componentDidMount(){
        
    }
    render(){
        // if (isAuthorized()) {
        //     // return <Redirect to='/' />
        // }
        let loginHeight = {
            height : window.innerHeight
        }
        return(
            <div class={this.props.className} style={loginHeight} >
            <Header />
                <div class="main-content  hl-dashboard-content hl-content p-50">
                    {this.props.children}
                </div>
            <Footer />
            </div>

        );
    }
}

export default Dashboard;