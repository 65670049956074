import React, { Component } from "react";
class TriggerModalSure extends Component {
  render() {
    return (
      <React.Fragment>
        {this.props.children &&
          React.cloneElement(this.props.children, {
            onClick: this.props.showModal,
          })}
      </React.Fragment>
    );
  }
}

export default TriggerModalSure;
