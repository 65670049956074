import React, { Component } from "react";
import HeaderLoggedIn from "../common/HeaderLoggedIn";
import Footer from "../common/Footer";
// import AdminMenu from '../admin/AdminMenu'
import { Link, Redirect } from "react-router-dom";
// import { isAuthorized } from '../Utilities/Auth';
import $ from "jquery";
import Constants from "../../Utilities/Constants";
import classNames from "classnames";
import _ from "lodash";
import Input from "../../components/Input";
import { SiteContext } from "../../Contexts/SiteContext";
// import { getSiteHelper, getSitesHelper } from "../Utilities/Helper";
import { getSite, getSites } from "../../Axios/Axios";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    console.log(props.location.site);
    // let site = this.props.location.site
    //   ? this.props.location.site
    //   : this.props.defaultSite
    //     ? this.props.defaultSite
    //     : "";
    this.state = {
      // activeSites: this.props.activeSites,
      // siteOptions: _.map(this.props.activeSites, (site, index) => {
      //   return { key: index, text: site.name, value: site.id };
      // }),
      activeSites: [],
      siteOptions: [],
      defaultSite: [],
      defaultSiteId: "",
      activeSiteList: [],
      accountSettings: false,
    };
    this.renderAllSiteList = this.renderAllSiteList.bind(this);
    this.handleSiteChange = this.handleSiteChange.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.handleAccountSettingsCollapse = this.handleAccountSettingsCollapse.bind(
      this
    );
    this.handleAccountSettingsToggle = this.handleAccountSettingsToggle.bind(
      this
    );
    this.getDefaultSite = this.getDefaultSite.bind(this);
    this.getActiveSites = this.getActiveSites.bind(this);
    this.setAccountSettingRef = (node) => {
      this.accountSettingsRef = node;
    };
    this.getActiveSites();
  }

  getActiveSites() {
    getSites((status, response) => {
      if (status === "success") {
        console.log(response);
        this.setState(
          {
            activeSites: response.sites.filter((site) => {
              return site.pixel_active !== "0";
            }),
            siteOptions: _.map(response.sites, (site, index) => {
              return { key: index, text: site.name, value: site.id };
            }),
          },
          () => {
            this.getDefaultSite();
            this.renderAllSiteList();
          }
        );
      } else {
      }
    });
  }

  getDefaultSite() {
    if (this.props.match.params.id) {
      getSite(this.props.match.params.id, (status, response) => {
        if (status === "success") {
          console.log(response);
          this.setState({
            defaultSite: response.site,
            defaultSiteId: response.site.id,
          });
        } else {
        }
      });
    } else if (this.state.activeSites) {
      console.log(this.state.activeSites);
      this.setState({
        defaultSite: this.state.activeSites[0],
        defaultSiteId: this.state.activeSites[0].id,
      });
    }
  }

  handleAccountSettingsToggle() {
    this.setState({
      accountSettings: !this.state.accountSettings,
    });
  }
  handleAccountSettingsCollapse() {
    this.setState({
      accountSettings: false,
    });
  }

  handleSiteChange(el, select) {
    this.props.history.push({
      pathname: _.replace(this.props.view, /:id/g, select.value),
      site: this.state.activeSites.filter((site) => {
        return site.id === select.value;
      })[0],
    });
  }

  handleClickOutside = (event) => {
    // const domNode = ReactDOM.findDOMNode(
    //   this.getElementsByClassName("notification-box")[0]
    // );

    if (
      !this.accountSettingsRef ||
      !this.accountSettingsRef.contains(event.target)
    ) {
      this.setState({
        accountSettings: false,
      });
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.location.site &&
      this.props.site !== nextProps.location.site
    ) {
      this.setState({
        defaultSiteId: nextProps.location.site.id,
        defalutSite: nextProps.location.site,
      });
    }
  }

  renderAllSiteList() {
    console.log(this.state.activeSites);
    let activeSiteList = this.state.activeSites
      ? _.map(this.state.activeSites, (site) => {
          return (
            <ul class="d-menu__list">
              <li class="d-menu__list-item">
                <Link
                  to={{
                    pathname: _.replace(
                      Constants.ROUTES.SITE_SEND_PUSH,
                      /:id/g,
                      site.id
                    ),
                    site: site,
                  }}
                >
                  <i class="fa fa-globe" />
                  {site.name}
                </Link>
              </li>
            </ul>
          );
        })
      : "";
    this.setState({
      activeSiteList: activeSiteList,
    });
  }

  componentWillUpdate(nextProps, nextState) {
    // console.log(
    //   this.state.siteOptions,
    //   this.state.defaultSiteId,
    //   nextProps.location
    // );
    // if (
    //   nextProps.location.site &&
    //   nextProps.location.site !== this.state.defaultSite
    // ) {
    //   this.setState({
    //     defaultSite: nextProps.location.site,
    //     defaultSiteId: nextProps.location.site.id,
    //   });
    // }
    // if (nextProps.site && nextProps.site !== this.props.site) {
    //   this.setState({
    //     defaultSite: nextProps.site,
    //     defaultSiteId: nextProps.site.id,
    //   });
    // }
    // if (
    //   nextProps.defaultSite &&
    //   nextProps.defaultSite !== this.props.defaultSite
    // ) {
    //   this.setState({
    //     defaultSite: nextProps.defaultSite,
    //     defaultSiteId: nextProps.defaultSite ? nextProps.defaultSite.id : false,
    //   });
    // }
    // if (
    //   nextProps.activeSites &&
    //   nextProps.activeSites !== this.props.activeSites
    // ) {
    //   this.setState(
    //     {
    //       activeSites: nextProps.activeSites,
    //       siteOptions: _.map(this.props.activeSites, (site, index) => {
    //         return { key: index, text: site.name, value: site.id };
    //       }),
    //     },
    //     () => {
    //       this.renderAllSiteList();
    //     }
    //   );
    // }
  }

  render() {
    var menuClass = (text) => {
      return classNames({
        "d-menu__list-item active": this.props.menuActive == text,
        "d-menu__list-item": true,
      });
    };

    // if (isAuthorized()) {
    //     // return <Redirect to='/' />
    // }
    return (
      <SiteContext.Provider value={this.state}>
        <div className={this.props.bodyClass}>
          {/* <HeaderLoggedIn history={this.props.history} pageTitle={this.props.pageTitle} navIcon={this.props.navIcon} menu2Active={this.props.menu2Active} subMenu={this.props.subMenu} {...this.props} /> */}
          <div class="big-container">
            <div class="sidebar-wrapper" />
            <div class="d-sidebar-left my-scrollbar open">
              <div class="d-sidebar-left__profile-box">
                <div class="info-card">
                  <div class="info-card__img pImage40">
                    <img src="https://avatars.dicebear.com/v2/initials/A.svg" />
                  </div>
                  <div class="info-card__content">
                    <p class="info-card__content-name">Admin</p>
                  </div>
                  <div class="clearfix" />
                </div>
              </div>
              <div class="pad-20 desktop-hide">
                <Link to="add-site.php" class="btn btn-primary full-width ">
                  Add New Site
                </Link>
              </div>
              <div class="d-sidebar-left__d-menu d-menu">
                <div>
                  <p class="d-menu-heading">Dashboard</p>
                  <ul class="d-menu__list">
                    <li className={menuClass(Constants.ROUTES.ADMIN_DASHBOARD)}>
                      <Link to={Constants.ROUTES.ADMIN_DASHBOARD}>
                        <i class="fa fa-home" aria-hidden="true" />
                        Home
                      </Link>
                    </li>
                  </ul>
                </div>
                <br />
                {this.state.defaultSite && (
                  <div>
                    <p class="d-menu-heading">{this.state.defaultSite.name}</p>
                    <ul class="d-menu__list">
                      <li
                        className={menuClass(Constants.ROUTES.SITE_SEND_PUSH)}
                      >
                        <Link
                          to={{
                            pathname: _.replace(
                              Constants.ROUTES.SITE_SEND_PUSH,
                              /:id/g,
                              this.state.defaultSiteId
                            ),
                            site: this.state.defalutSite,
                          }}
                        >
                          <i class="fa fa-tachometer" aria-hidden="true" />
                          Send Notification
                        </Link>
                      </li>
                      <li
                        className={menuClass(
                          Constants.ROUTES.SITE_NOTIF_HISTORY
                        )}
                      >
                        <Link
                          to={{
                            pathname: _.replace(
                              Constants.ROUTES.SITE_NOTIF_HISTORY,
                              /:id/g,
                              this.state.defaultSiteId
                            ),
                            site: this.state.defalutSite,
                          }}
                        >
                          <i class="fa fa-tachometer" aria-hidden="true" />
                          Notification History
                        </Link>
                      </li>
                      {/* <li className={menuClass(Constants.ROUTES.SITE_STAT)}>
                      <Link
                        to={{
                          pathname: _.replace(
                            Constants.ROUTES.SITE_STAT,
                            /:id/g,
                            this.state.defaultSiteId
                          ),
                          site: this.state.defalutSite,
                        }}
                      >
                        <i class="fa fa-tasks" aria-hidden="true" />
                        Site Stats
                      </Link>
                    </li> */}
                    </ul>
                  </div>
                )}
                <br />
                {/* {this.props.allSites && ( */}
                <div>
                  <p class="d-menu-heading">All Sites</p>

                  {this.state.activeSiteList}
                </div>
                {/* )} */}
                <br />
              </div>
            </div>
            <div class="d-main-container slide-left">
              <div class="d-header">
                <div class="row">
                  <div class="col-md-8 col-4">
                    <div class="d-header__left">
                      <i class="fa fa-bars menu-icon is-sidebar-open " />
                      <h3>
                        {!this.props.match.params.id && <span>Dashboard</span>}
                        {this.props.match.params.id && (
                          <Input
                            type="select-s"
                            elementonly="true"
                            options={this.state.siteOptions}
                            //   defaultValue={this.state.defaultSiteId}
                            value={this.state.defaultSiteId}
                            onChange={this.handleSiteChange}
                          />
                        )}
                      </h3>
                    </div>
                  </div>
                  {/* <div class="col-md-5 col-6 mobile-hide">
                  <div class="inbox-left__search d-form">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="Search Site"
                    />
                    <i class="fa fa-search"></i>
                  </div>
                </div> */}
                  <div class="col-md-4 text-right col-8">
                    <div class="d-header__right">
                      <Link
                        to={Constants.ROUTES.ADD_SITE}
                        class="btn btn-primary mobile-hide"
                      >
                        Add Site
                      </Link>
                      <div
                        class="d-header__right-icon"
                        // tabIndex="0"
                        // onBlur={this.handleAccountSettingsCollapse}
                        ref={this.setAccountSettingRef}
                      >
                        <img
                          // src={require("../../assets/images/profil.jpg")}
                          src="https://avatars.dicebear.com/v2/initials/A.svg"
                          class="d-profile-image"
                          onClick={this.handleAccountSettingsToggle}
                        />
                        <div
                          class="notification-box account-box"
                          style={{
                            display: this.state.accountSettings
                              ? "block"
                              : "none",
                          }}
                        >
                          <div>
                            <Link to="/change-password">
                              <i class="fa fa-user" />
                              Change Password
                            </Link>
                            <Link to="/logout">
                              <i class="fa fa-power-off" />
                              Log Out
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-container">{this.props.children}</div>
            </div>
            <div class="d-sidebar-right" />
          </div>
        </div>
      </SiteContext.Provider>
    );
  }
}

export default Dashboard;
