import React, {Component} from 'react';
import classNames from 'classnames';

export default class Alert2 extends Component{
    constructor(props){
        super(props);
    }

    componentDidMount(){

    }

    render(){

        var alertClass = classNames("alert", this.props.className,{

        });
        return(
            <div class={alertClass} role="alert">
                {this.props.text}
            </div>
        );
    }
}