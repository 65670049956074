import React, { Component } from "react";
// import Header from '../../common/Header';
// import Footer from '../../common/Footer';
import { Link } from "react-router-dom";
import Site from "../../layouts/Site";
// import { isAuthorized } from '../Utilities/Auth';
// import $ from 'jquery';
import Input from "../../../components/Input";
import Form from "../../../components/Form";
import { formErrors } from "../../../Utilities/Form";
import Alert2 from "../../../components/Alert2";
import Constants from "../../../Utilities/Constants";
import { login } from "../../../Axios/Axios";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertTitle: "",
      email: "",
      password: "",
      error: {},
      errorSalut: "",
      errorMsg: "",
    };

    this.updateEmail = this.updateEmail.bind(this);
    this.updatePassword = this.updatePassword.bind(this);
    this.validateEmail = this.validateEmail.bind(this);
    this.epError = React.createRef();
    this.epErrorPass = React.createRef();
    this.epMobile = React.createRef();
    this.epPassword = React.createRef();
  }

  updatePassword(e) {
    this.setState({ password: e.target.value });
  }

  updateEmail(e) {
    var reg = /\S+@\S+\.\S+/;
    if (reg.test(e.target.value)) {
      this.setState({ email: e.target.value });
    } else {
    }
  }

  validateEmail(e) {
    e.preventDefault();
    this.setState({
      errorMsg: false,
      errorSalut: "",
    });
    let email = this.state.email;
    let error = false;
    console.log(email);
    let password = this.state.password;
    if (!/\S+@\S+\.\S+/.test(email)) {
      // console.log(email);
      this.setState({
        error_email: "Invalid Email",
      });
      error = true;
    } else {
      this.setState({
        error_email: false,
      });
    }
    if (password.length < 1) {
      this.setState({
        error_password: "Please enter password",
      });
      error = true;
    } else {
      this.setState({
        error_password: false,
      });
    }
    if (error) {
      return false;
    } else {
      this.setState({
        // 'error_email' :
      });
    }
    localStorage.setItem("email", email);
    login(email, password, (response, error) => {
      console.log(response, error);
      if (response == "error") {
        this.setState({
          errorMsg: error,
          errorSalut: "Error",
        });
      } else {
        this.props.history.push(Constants.ROUTES.ADMIN_DASHBOARD);
      }
    });
  }

  componentDidMount() {}
  render() {
    // if (isAuthorized()) {
    //     // return <Redirect to='/offers' />
    // }
    return (
      <Site pageTitle="Add Project" className="dashboard dash-login-page">
        <div class="container">
          {this.state.errorMsg && (
            <Alert2 text={this.state.errorSalut + ": " + this.state.errorMsg} />
          )}
          <div class="login-container">
            <div class=" d-box">
              <div class="text-center">
                <h3>LearnCBSE: Login</h3>
                <span class="color-grey">Web Push Platform</span>
              </div>
              <br />
              <br />
              {this.state.error_email && (
                <span class="ep-error" ref={this.epError} />
              )}

              <form class="d-form" action="dashboard.php">
                <div class="form-group">
                  <input
                    class="form-control m-input"
                    type="text"
                    placeholder="Email"
                    name="email"
                    autocomplete="off"
                    ref={this.epEmail}
                    onChange={this.updateEmail}
                    autofocus
                  />
                  {this.state.error_email && (
                    <span class="ep-error">{this.state.error_email}</span>
                  )}
                </div>
                <br />
                <div class="form-group">
                  <input
                    class="form-control m-input m-login__form-input--last"
                    type="password"
                    placeholder="Password"
                    name="password"
                    ref={this.epPassword}
                    onChange={this.updatePassword}
                  />
                  {this.state.error_password && (
                    <span class="ep-error">{this.state.error_password}</span>
                  )}
                  {/* <Link to="#" class="color-grey pull-right hint-text">
                    {" "}
                    Forgot Password ?
                  </Link> */}
                </div>
                {/* <div class="ui checkbox">
                  <input type="checkbox" name="" />
                  <label class="form-check-label" for="gridCheck">
                    <p> Remember me</p>
                  </label>
                </div> */}
                <br />
                <input
                  type="submit"
                  class="btn d-btn btn-primary full-width"
                  onClick={this.validateEmail}
                  value="Login"
                  name=""
                />
              </form>
              <br />
              {/* <div class="text-center">
                                    <br />
                                    <p class="color-grey">Don't have an account? <Link to="signup" class="d-link"> Create an account</Link></p>
                                </div> */}
            </div>
          </div>
        </div>
      </Site>
    );
  }
}

export default Login;
