import React, { Component } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Link, Redirect } from "react-router-dom";
import Site from "../../layouts/Site";
// import { isAuthorized } from '../Utilities/Auth';
import $ from "jquery";
import Input from "../../../components/Input";
import Form from "../../../components/Form";
import { formErrors } from "../../../Utilities/Form";
import Alert2 from "../../../components/Alert2";
import Constants from "../../../Utilities/Constants";
import { reset } from "../../../Axios/Axios";

class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      alertTitle: "",
      password: "",
      password_confirmation: "",
      error: {},
    };

    // this.updateEmail = this.updateEmail.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  onChange(name, value) {
    this.setState({
      [name]: value,
    });
  }
  // updateEmail(e) {
  //   var reg = /\S+@\S+\.\S+/;
  //   if (reg.test(e.target.value)) {
  //     this.setState({ email: e.target.value });
  //   } else {
  //     if (this.state.email.length > 1) {
  //       e.target.value = this.state.email;
  //     }
  //   }
  // }

  submit(e) {
    e.preventDefault();
    let error = false;
    let password = this.state.password;
    let oldpass = this.state.oldpass;
    let password_confirmation = this.state.password_confirmation;

    if (oldpass.length < 1) {
      this.setState({
        errorOldPass: "Please enter exisitng password",
      });
      error = true;
    } else {
      this.setState({
        errorOldPass: false,
      });
    }

    if (password.length < 1) {
      this.setState({
        errorPassword: "Please enter password",
      });
      error = true;
    } else {
      this.setState({
        errorPassword: false,
      });
    }
    if (password_confirmation.length < 1) {
      this.setState({
        errorPasswordConfirmation: "Please enter password",
      });
      error = true;
    } else {
      this.setState({
        errorPasswordConfirmation: false,
      });
    }

    if (password_confirmation !== password) {
      this.setState({
        errorPasswordConfirmation: "Password confirmation doesn't match",
      });
      error = true;
    } else {
      this.setState({
        errorPasswordConfirmation: false,
      });
    }

    if (error) return;
    let email = localStorage.getItem("email");
    reset(email, password_confirmation, password, (response, error) => {
      console.log(response, error);
      if (response == "success") {
        this.props.history.push(Constants.ROUTES.ADMIN_DASHBOARD);
        // this.setState({
        //   errorMsg: error,
        //   errorSalut: "Error",
        // });
      } else {
      }
    });
  }

  componentDidMount() {}
  render() {
    // if (isAuthorized()) {
    //     // return <Redirect to='/offers' />
    // }
    return (
      <Site pageTitle="Change Password" className="dashboard dash-login-page">
        <div class="container">
          <div class="login-container">
            <div class="d-box">
              <div class="text-center">
                <h3>Change Password</h3>
              </div>
              <br />
              <br />
              <form class="d-form" action="dashboard.php">
                <div class="form-group">
                  <label for="">Old Password</label>
                  <Input
                    class="form-control m-input"
                    type="text"
                    placeholder="Old Password"
                    name="oldpass"
                    autocomplete="off"
                    onChange={this.onChange}
                    autofocus
                  />
                </div>
                <div class="form-group">
                  <label for="">New Password</label>
                  <Input
                    class="form-control m-input"
                    type="text"
                    placeholder="New Password"
                    name="password"
                    autocomplete="off"
                    onChange={this.onChange}
                    autofocus
                    errortext={this.state.errorPassword}
                  />
                </div>
                <div class="form-group">
                  <label for="">Confirm New Password</label>
                  <Input
                    class="form-control m-input"
                    type="text"
                    placeholder="Confirm New Password"
                    name="password_confirmation"
                    autocomplete="off"
                    onChange={this.onChange}
                    autofocus
                    errortext={this.state.errorPasswordConfirm}
                  />
                </div>
                <div class="form-group">
                  <input
                    type="submit"
                    class="btn hl-btn d-btn"
                    name=""
                    value="Submit"
                    onClick={(e) => this.submit(e)}
                  />
                </div>
              </form>
              <br />
              <br />
              <div class="text-center">
                <p class="color-grey">
                  <Link to="/dashboard" class="d-link">
                    <i class="fa fa-home" /> Dashboard
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Site>
    );
  }
}

export default ChangePassword;
