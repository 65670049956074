import React, {Component} from 'react';
// import Alert from '../components/Alert';
// import Header from '../components/Header';
// import Footer from '../components/Footer';
import { Link, Redirect } from 'react-router-dom';
// import { FormGroup, Label, Input } from 'reactstrap';
// import axios from 'axios';
import { isAuthorized } from '../Utilities/Auth';
import $ from 'jquery';


class Home extends Component{

    componentDidMount(){
    }
    render(){
        if (isAuthorized()) {
            return <Redirect to='/' />
        }
        return(
         <div></div>
        );
    }
}

export default Home;