import React, { Component } from "react";
import classNames from "classnames";
import Select from "react-select";
import { Dropdown, Checkbox } from "semantic-ui-react";
import Dropzone from "react-dropzone";

import _ from "lodash";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

export default class Input extends Component {
  constructor(props) {
    super(props);
    this.state = {
      [this.props.name]: this.props.value
        ? this.appendPrepend(this.props.value)
        : "",
      error: [],
      multiSelectTouched: "",
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleDateSelect = this.handleDateSelect.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
    this.handleOnDrop = this.handleOnDrop.bind(this);
  }

  handleOnDrop = (acceptedFiles) => {
    console.log(acceptedFiles);
    this.props.onDrop(acceptedFiles);
  };

  componentDidMount() {}

  handleDateChange(name, value) {
    console.log(name, value);
    this.props.onChange(name, value);
  }

  handleDateSelect(name, value) {
    console.log(name, value);
    this.props.onChange(name, value);
  }

  handleKeyDown(e) {
    // console.log(e.key);
    // console.log(e.keyCode);
    if (e.keyCode === 8) {
      e.stopPropagation();
      let value = e.target.value;
      let name = e.target.name;
      value = this._removeAppendPrependToString(
        value,
        "append",
        this.props.append
      );
      this.setState({
        [name]: value,
      });
      this.props.onChange(name, this.removeAppendPrepend(value));
    }
  }

  handleInputChange(e, a) {
    // console.log(e.target.value);
    // console.log(a);

    const target = e.target;
    const selectActive = a ? true : false;
    let name = "";
    let value = "";
    if (target) {
      if (target.type === "select-multiple") {
        // console.log(e.target.options);
        value = [];
        for (let i = 0, l = target.options.length; i < l; i++) {
          if (target.options[i].selected) {
            value.push(target.options[i].value);
          }
        }
      } else {
        value = target.type === "checkbox" ? target.checked : target.value;
      }
      // console.log(e.key);
      name = target.name;
      value = this.appendPrepend(value);
      this.setState({
        [name]: value,
      });
    } else {
      if (selectActive) {
        if (this.props.type === "select") {
          name = a.name;
          value = e.id;
          this.setState({
            [name]: value,
          });
        } else if (this.props.type === "select-multiple") {
          if (e) {
            name = a.name;
            value = _.map(e, "id");
            // console.log(value);
            this.setState((state) => {
              return {
                [name]: e,
                multiSelectTouched: true,
              };
            });
          }
        }
      }
    }

    this.props.onChange(name, this.removeAppendPrepend(value));
  }

  appendPrepend(value) {
    if (this.props.restrictions) {
      value = this._appendPrependToString(
        value,
        "restrictions",
        this.props.restrictions
      );
    }
    // value = this._appendPrependToString(value, 'replace_regex', this.props.replace_regex);
    if (this.props.append) {
      value = this._appendPrependToString(value, "append", this.props.append);
    }
    if (this.props.prepend) {
      value = this._appendPrependToString(value, "prepend", this.props.prepend);
    }
    if (this.props.allow_regex) {
      value = this._appendPrependToString(
        value,
        "allow_regex",
        this.props.allow_regex
      );
    }
    return value;
  }

  removeAppendPrepend(value) {
    if (this.props.append) {
      value = this._removeAppendPrependToString(
        value,
        "append",
        this.props.append
      );
    }
    if (this.props.prepend) {
      value = this._removeAppendPrependToString(
        value,
        "prepend",
        this.props.prepend
      );
    }
    return value;
  }

  _appendPrependToString(value, type, string) {
    switch (type) {
      case "append":
        //append to value
        return value.length > 0 && string
          ? value.includes(string) && value.endsWith(string)
            ? value
            : value.replace(string, "") + string
          : value;
      case "prepend":
        //prepend to value
        return value.length > 0 && string
          ? value.startsWith(string)
            ? value
            : string + value
          : value;
        break;
      case "replace_regex":
        //regex to value
        return value.length > 0 && string ? value.replace(string, "") : value;
      case "allow_regex":
        //regex to value
        value = this._removeAppendPrependToString(
          value,
          "append",
          this.props.append
        );
        value = this._removeAppendPrependToString(
          value,
          "prepend",
          this.props.prepend
        );
        // console.log(string.test(value), value);
        string.lastIndex = 0;
        value =
          value.length > 0 && string
            ? string.test(value)
              ? value
              : value.substr(0, value.length - 1)
            : value;
        value = this._appendPrependToString(
          value,
          "prepend",
          this.props.prepend
        );
        return this._appendPrependToString(value, "append", this.props.append);
      case "restrictions":
        //restrictions to value
        _.each(string, (str) => {
          while (value.indexOf(str) > -1) {
            value = value.replace(str, "");
          }
          // console.log(value);
          return value.trim();
        });
        // console.log(value);
        return value.trim();
      default:
        return value;
    }
  }

  _removeAppendPrependToString(value, type, string) {
    switch (type) {
      case "append":
        //append to value
        // return string?value.includes(string,0)?value.substr(0, value.length-1):value+string:value;
        return string
          ? value.includes(string)
            ? value.replace(string, "")
            : value
          : value;
        break;
      case "prepend":
        //prepend to value
        // return string?value.includes(string,0)?value.substr(1):string+value:value;
        return string
          ? value.includes(string)
            ? value.replace(string, "")
            : value
          : value;
        break;

      default:
        return value;
        break;
    }
  }

  componentDidUpdate() {
    if (this.props.prefil && this.state[this.props.name] != this.props.prefil) {
      if (this.props.type == "select" || this.props.type == "select-multiple") {
        // console.log(this.state[this.props.name]);
      } else {
        this.setState({
          [this.props.name]: this.props.prefil,
        });
      }
    }
  }

  componentWillUpdate(nextProps, nextState) {
    console.log(this.props.value, nextProps.value);
    if (nextProps.value !== this.props.value) {
      // this.setState({
      //   [this.props.name]: this.props.value,
      // });
    }
  }

  resetError() {
    this.setState({
      error: "",
    });
  }

  render() {
    let type = this.props.type;

    let html = "";
    let element = "";
    switch (type) {
      case "text":
        element = (
          <div className={this.props.append ? "hl-input-append" : ""}>
            <input
              {...this.props}
              className={classNames("form-control hl-input", {
                "hl-input-error": this.props.errortext,
              })}
              type="text"
              name={this.props.name}
              value={
                typeof this.props.value !== "undefined"
                  ? this.props.value
                  : this.state[this.props.name]
              }
              onChange={this.handleInputChange}
              onKeyDown={this.handleKeyDown}
              placeholder={this.props.append ? "" : this.props.placeholder}
            />
            <span
              className={classNames("hl-form__help", {
                "hl--hide": !this.props.errortext,
              })}
            >
              {this.props.errortext}
            </span>
          </div>
        );
        html = (
          <div className="form-group row">
            {/* <label for={ this.state[this.props.name] + "-text-input" } className="col-md-2 nopadding col-form-label">{this.props.label}</label> */}
            <div className="col-md-12 nopadding">
              {element}
              {/* <span
                className={classNames("hl-form__help", {
                  "hl--hide": !this.props.errortext,
                })}
              >
                {this.props.errortext}
              </span> */}
            </div>
          </div>
        );
        break;
      case "hidden":
        element = (
          <input
            className=""
            type="hidden"
            name={this.props.name}
            value={this.state[this.props.name]}
            onChange={this.handleInputChange}
          />
        );
        html = (
          <div>
            {element}
            <span
              className={classNames("hl-form__help", {
                "hl--hide": !this.props.errortext,
              })}
            >
              {this.props.errortext}
            </span>
          </div>
        );
        break;
      case "textarea":
        element = (
          <textarea
            className={classNames("form-control hl-input", {
              "hl-input-error": this.props.errortext,
            })}
            name={this.props.name}
            id={this.props.name}
            onChange={this.handleInputChange}
            value={
              typeof this.props.value !== "undefined"
                ? this.props.value
                : this.state[this.props.name]
            }
          />
        );
        html = (
          <div className="form-group row">
            {/* <label for={ this.state[this.props.name] + "-text-input" } className="col-md-2 nopadding col-form-label">{this.props.label}</label> */}
            <div className="col-md-12 nopadding">
              {element}
              <span
                className={classNames("hl-form__help", {
                  "hl--hide": !this.props.errortext,
                })}
              >
                {this.props.errortext}
              </span>
            </div>
          </div>
        );
        break;

      case "select":
        this.setState((state) => {
          if (
            state[this.props.name] !=
            this.props.options
              .filter((option, i) => (option ? true : false))
              .find((option) => option.id == this.props.prefil)
          )
            return {
              [this.props.name]: this.props.options
                .filter((option, i) => (option ? true : false))
                .find((option) => option.id == this.props.prefil),
            };
        });

        // console.log(this.state[this.props.name]);
        element = (
          <Select
            className="form-control hl-input"
            onChange={this.handleInputChange}
            name={this.props.name}
            id={this.props.name}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            options={this.props.options.filter(
              (option, i) => (option ? true : false)
            )}
            value={this.state[this.props.name]}
          >
            {/* {this.props.options.map((option, i) => option?<option value={option.id} selected={this.props.prefil == option.id?'selected':''}>{option.name}</option>:'')} */}
          </Select>
        );
        html = (
          <div className="form-group row">
            {/* <label for={ this.state[this.props.name] + "-text-input" } className="col-md-2 nopadding col-form-label">{this.props.label}</label> */}
            <div className="col-md-12 nopadding">
              {element}
              <span
                className={classNames("hl-form__help", {
                  "hl--hide": !this.props.errortext,
                })}
              >
                {this.props.errortext}
              </span>
            </div>
          </div>
        );
        break;

      case "select-multiple":
        let selectedOptions = this.props.options
          .filter((option, i) => (option ? true : false))
          .filter(
            (option) =>
              this.props.prefil ? this.props.prefil.includes(option.id) : false
          );
        // console.log(selectedOptions);
        this.setState((state) => {
          let alreadyIn = state[this.props.name];
          if (
            !_.isEqual(alreadyIn, selectedOptions) &&
            !this.state.multiSelectTouched
          ) {
            return {
              [this.props.name]: this.props.options
                .filter((option, i) => (option ? true : false))
                .filter(
                  (option) =>
                    this.props.prefil
                      ? this.props.prefil.includes(option.id)
                      : false
                ),
            };
          }
        });
        element = (
          <Select
            className="form-control hl-input"
            onChange={this.handleInputChange}
            name={this.props.name}
            id={this.props.name}
            multiple
            //  options = {this.props.options.map((option, i) => option?{value: option.id, label:option.name}:'')}
            getOptionLabel={(option) => option.name}
            getOptionValue={(option) => option.id}
            options={this.props.options.filter(
              (option, i) => (option ? true : false)
            )}
            value={this.state[this.props.name]}
            // value = {this.props.prefil}
            isMulti={true}
          >
            {/* {this.props.options.map((option, i) => option?<option value={option.id} selected={(this.props.prefil && (this.props.prefil.indexOf(option.id) >= 0))?'selected':''}>{option.name}</option>:'')} */}
          </Select>
        );
        html = (
          <div className="form-group row">
            {/* <label for={ this.state[this.props.name] + "-text-input" } className="col-md-2 nopadding col-form-label">{this.props.label}</label> */}
            <div className="col-md-12 nopadding">
              {element}
              <span
                className={classNames("hl-form__help", {
                  "hl--hide": !this.props.errortext,
                })}
              >
                {this.props.errortext}
              </span>
            </div>
          </div>
        );
        break;

      case "checkbox":
        element = (
          <span className="m-switch">
            <label>
              <input
                type="checkbox"
                name={this.props.name}
                id="isActive"
                checked={this.state[this.props.name]}
                onChange={this.handleInputChange}
              />
              <span />
            </label>
          </span>
        );

        html = (
          <div className="form-group row">
            {/* <label  className="col-md-2 nopadding col-form-label">{this.props.label}</label> */}
            <div className="col-md-12 nopadding">
              {element}
              <br />
              <span
                className={classNames("hl-form__help", {
                  "hl--hide": !this.props.errortext,
                })}
              >
                {this.props.errortext}
              </span>
            </div>
          </div>
        );
        break;

      case "select-s":
        element = (
          <div>
            <Dropdown
              placeholder={this.props.placeholder}
              {...this.props}
              options={this.props.options}
            />
            <span
              className={classNames("hl-form__help", {
                "hl--hide": !this.props.errortext,
              })}
            >
              {this.props.errortext}
            </span>
          </div>
        );

        html = (
          <div className="form-group row">
            <div className="col-md-12 nopadding">
              {element}
              <br />
              <span
                className={classNames("hl-form__help", {
                  "hl--hide": !this.props.errortext,
                })}
              >
                {this.props.errortext}
              </span>
            </div>
          </div>
        );

        break;

      case "checkbox-radio":
        element = (
          <div>
            <Checkbox
              placeholder={this.props.placeholder}
              {...this.props}
              type="checkbox"
            />
            <span
              className={classNames("hl-form__help", {
                "hl--hide": !this.props.errortext,
              })}
            >
              {this.props.errortext}
            </span>
          </div>
        );

        html = (
          <div className="form-group row">
            <div className="col-md-12 nopadding">
              {element}
              <br />
              <span
                className={classNames("hl-form__help", {
                  "hl--hide": !this.props.errortext,
                })}
              >
                {this.props.errortext}
              </span>
            </div>
          </div>
        );

        break;

      case "dropzone":
        element = (
          <Dropzone onDrop={this.handleOnDrop}>
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                {this.props.children}
              </div>
            )}
          </Dropzone>
        );
        html = (
          <div className="form-group row">
            <div className="col-md-12 nopadding">
              {element}
              <span
                className={classNames("hl-form__help", {
                  "hl--hide": !this.props.errortext,
                })}
              >
                {this.props.errortext}
              </span>
            </div>
          </div>
        );
        break;

      case "datepicker":
        const ExampleCustomInput = ({ value, onClick }) => (
          <input
            type="text"
            className={classNames("form-control hl-input", {
              "hl-input-error": this.props.errortext,
            })}
            placeholder={this.props.placeholder}
            onClick={onClick}
            value={value}
          />
        );
        element = (
          <React.Fragment>
            <DatePicker
              {...this.props}
              showPopperArrow={false}
              selected={this.props.startDate}
              onChange={this.handleDateChange.bind(this, this.props.name)}
              // onSelect={this.handleDateSelect.bind(this, this.props.name)} //when day is clicked
              placeholderText={this.props.placeholder}
              customInput={<ExampleCustomInput />}
            />
            <span
              className={classNames("hl-form__help", {
                "hl--hide": !this.props.errortext,
              })}
            >
              {this.props.errortext}
            </span>
          </React.Fragment>
        );
        html = (
          <div className="form-group row">
            <div className="col-md-12 nopadding">
              {element}
              <span
                className={classNames("hl-form__help", {
                  "hl--hide": !this.props.errortext,
                })}
              >
                {this.props.errortext}
              </span>
            </div>
          </div>
        );
        break;
    }
    if (this.props.elementonly) {
      html = element;
    }
    return html;
  }
}
