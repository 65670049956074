import React, { Component } from "react";
import classNames from "classnames";
import { Modal } from "react-bootstrap";

export default class ModalUI extends Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: this.props.show,
    };
  }

  handleClose(e) {
    this.setState({ show: false });
    this.props.onClose();
  }

  handleShow() {
    this.setState({ show: true });
  }

  componentDidMount() {}
  componentWillUpdate(nextProps, nextState) {
    if (
      nextProps.show !== this.state.show ||
      nextProps.show != this.props.show
    ) {
      this.setState({ show: nextProps.show });
    }
  }

  render() {
    var alertClass = classNames("alert", this.props.className, {});
    return (
      <Modal
        id="myModal"
        class="modal fade"
        role="dialog"
        bsSize={this.props.size}
        show={this.state.show}
        onHide={this.handleClose}
      >
        <Modal.Header class="modal-header">
          <h4 class="modal-title">{this.props.title}</h4>
        </Modal.Header>
        <Modal.Body class="modal-body">{this.props.children}</Modal.Body>
      </Modal>
    );
  }
}
