import React, { Component } from "react";
import Dashboard from "../../layouts/Dashboard";
import { Link } from "react-router-dom";
import Constants from "../../../Utilities/Constants";
import _ from "lodash";
import Input from "../../../components/Input";
import $ from "jquery";
import Datatable from "react-data-table-component";
import { getNotifications } from "../../../Axios/Axios";
import moment from "moment";
import classNames from "classnames";

export default class History extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      siteId: this.props.match.params.id,
      blockUI: true,
    };
    this.getNotifications = this.getNotifications.bind(this);
    this.handleBlockUI = this.handleBlockUI.bind(this);
  }

  handleBlockUI(val) {
    this.setState({
      blockUI: val,
    });
  }

  componentDidMount() {
    this.getNotifications();
  }

  getNotifications() {
    getNotifications(this.state.siteId, (status, response) => {
      if (status === "success") {
        console.log(response);
        this.setState({
          data: response.notifications,
        });
        this.handleBlockUI(false);
      }
    });
  }

  componentWillUpdate(nextProps) {
    console.log(nextProps);
    if (
      nextProps.location.site &&
      nextProps.location.site.id !== this.state.siteId
    ) {
      this.setState(
        {
          siteId: nextProps.location.site.id,
        },
        () => {
          this.getNotifications();
        }
      );
    }
  }

  render() {
    const columns = [
      {
        name: "Date",
        selector: "created_at",
        sortable: true,
        cell: (row) => {
          return moment(row.created_at)
            .local()
            .format("DD MMM YYYY, HH:mm A")
            .toString();
        },
      },
      {
        name: "Title",
        selector: "title",
        sortable: true,
        wrap: true,
      },
      {
        name: "Message",
        selector: "message",
        sortable: true,
        wrap: true,
      },
      {
        name: "Total/Sent",
        selector: "stat",
        sortable: false,
        cell: (row) => (
          <p>
            {row.stat ? row.stat.total : "-"} /{" "}
            {row.stat ? row.stat.views : "-"}
          </p>
        ),
      },
      {
        name: "Status",
        selector: "send_status",
        sortable: true,
        cell: (row) => (
          <p
            className={classNames({
              "d-label": true,
              "d-label-info": row.send_status === "running",
              "d-label-primary": row.send_status === "scheduled",
              "d-label-success": row.send_status === "complete",
              "d-label-warning": row.send_status === "pending",
              "d-label-danger": row.send_status === "error",
            })}
          >
            {row.send_status}
          </p>
        ),
      },
      {
        name: "",
        selector: "id",
        cell: (row) => (
          <Link
            className="btn-icon"
            to={{
              pathname: _.replace(
                Constants.ROUTES.SITE_SEND_PUSH,
                /:id/g,
                row.site_id
              ),
              notification: row,
            }}
            alt="Duplicate this notification"
          >
            <i class="fa fa-copy" />
          </Link>
        ),
      },
    ];

    if (this.state.blockUI) {
      return <div className="ui-blocker loader" />;
    }

    return (
      <Dashboard
        {...this.props}
        match={this.props.match}
        history={this.props.history}
        pageTitle="Dashboard"
        menuActive={Constants.ROUTES.SITE_NOTIF_HISTORY}
        bodyClass="dashboard"
      >
        <div class="stats-container-2">
          <div class="d-box d-box-table d-form">
            <div class="d-stats__chart-header chart-header l-chart-header">
              <div class="chart-header__text pull-left">
                <h3 class="chart-heading">Notification History</h3>
              </div>
              <div class="chart-header__action-box pull-right">
                {/* <select class="ui dropdown d-dropdown">
                  <option value="1">Jan</option>
                  <option value="2">Feb</option>
                  <option value="3">Mar</option>
                  <option value="4">Apr</option>
                  <option value="5">May</option>
                </select>
                <select class="ui dropdown d-dropdown">
                  <option value="1">2015</option>
                  <option value="1">2016</option>
                  <option value="1">2017</option>
                </select> */}
              </div>
              <div class="clearfix" />
            </div>
            <Datatable
              //   title="Notifications"
              columns={columns}
              data={this.state.data}
              defaultSortField="created_at"
              defaultSortAsc={false}
              noHeader={true}
              pagination
            />
            {/* <table class="table dash-table">
              <tbody>
                <tr>
                  <td>
                    <div class="d-sidebar-left__profile-box">
                      <div class="info-card">
                        <div class="info-card__content">
                          <p class="info-card__content-name">
                            This is a test message
                          </p>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </td>
                  <td>
                    0 <br /> Sent
                  </td>
                  <td>
                    <p class="d-label d-label-success">Send Complete</p>
                  </td>
                  <td>
                    <a href="#" class="d-btn hl-btn btn">
                      View
                    </a>
                  </td>
                  <td>
                    <select class="ui dropdown d-dropdown">
                      <option value="1">Duplicate</option>
                      <option value="1">Delete</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="d-sidebar-left__profile-box">
                      <div class="info-card">
                        <div class="info-card__content">
                          <p class="info-card__content-name">
                            This is a test message
                          </p>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </td>
                  <td>
                    0 <br /> Sent
                  </td>
                  <td>
                    <p class="d-label d-label-success">Send Complete</p>
                  </td>
                  <td>
                    <a href="#" class="d-btn hl-btn btn">
                      View
                    </a>
                  </td>
                  <td>
                    <select class="ui dropdown d-dropdown">
                      <option value="1">Duplicate</option>
                      <option value="1">Delete</option>
                    </select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <div class="d-sidebar-left__profile-box">
                      <div class="info-card">
                        <div class="info-card__content">
                          <p class="info-card__content-name">
                            This is a test message
                          </p>
                        </div>
                        <div class="clearfix"></div>
                      </div>
                    </div>
                  </td>
                  <td>
                    0 <br /> Sent
                  </td>
                  <td>
                    <p class="d-label d-label-success">Send Complete</p>
                  </td>
                  <td>
                    <a href="#" class="d-btn hl-btn btn">
                      View
                    </a>
                  </td>
                  <td>
                    <select class="ui dropdown d-dropdown">
                      <option value="1">Duplicate</option>
                      <option value="1">Delete</option>
                    </select>
                  </td>
                </tr>
              </tbody>
            </table> */}
          </div>
        </div>
      </Dashboard>
    );
  }
}
