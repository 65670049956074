module.exports = {
    ROUTES: {
        ADD_SITE: '/add-site',
        EDIT_SITE: '/site/:id/basic',
        ADD_SITE_WELCOME_NOTIF: '/site/:id/welcome',
        ADD_SITE_PIXEL_INSTALL: '/site/:id/pixel',
        ADD_SITE_PIXEL_VERIFY: '/site/:id/pixel-verify',
        SITE_STAT: '/site/:id',
        SITE_SEND_PUSH: '/site/:id/send-new',
        SITE_NOTIF_HISTORY: '/site/:id/notifications',
        USER_CHANGE_PASSWORD: '/change-password',
        LOGOUT: '/logout',
        LOGIN: '/login',
        ADMIN_DASHBOARD: '/dashboard',

    },
    ROLES_PERMISSIONS: {
        USER_SUPERADMIN: [
            'site'
        ],
    }
};