import React, {Component} from 'react';
import Alert2 from '../components/Alert2'
import classNames from 'classnames'
import _ from 'lodash'

export default class Form extends Component{
    static contextTypes = {
        router: () => true, // replace with PropTypes.object if you use them
      }
    

    constructor(props){
        super(props);

        this.state = {
            formError : '',
            submitButtonText : this.props.submitButtonText?this.props.submitButtonText:'Submit',
            cancelButtonText : this.props.cancelButtonText?this.props.cancelButtonText:'Cancel',
            submitButtonEnabled : this.props.submitButtonEnabled?this.props.submitButtonEnabled:true,
            cancelButtonEnabled : this.props.cancelButtonEnabled?this.props.cancelButtonEnabled:false
        }
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleSubmit(e){
        e.preventDefault();
        this.props.onSubmit();
    }

    componentDidUpdate(){
    }

    componentDidMount(){

    }
    

   

        render(){
            return(
                <form class="" onSubmit={this.handleSubmit}>
                    { this.props.formError && 
                    <div class="form-group ">
                        <Alert2 className={classNames({'hl--hide' : !this.props.formError})} text={this.props.formError}/>
                    </div>
                    }
                        {this.props.children}
                    <div class="row">
                        <div class="col-md-12 nopadding">
                            {this.state.submitButtonEnabled &&
                                <button type="submit" class="btn btn-primary"
                                    >{this.state.submitButtonText}</button>
                            }
                            {
                                this.state.cancelButtonEnabled && 
                                <button type="button" class="btn btn-secondary" onClick={ this.context.router.history.goBack }>{this.state.cancelButtonText}</button>
                            }
                        </div>
                    </div>
            </form>
            );
        }

    }