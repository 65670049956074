import React from "react";
import { Link } from "react-router-dom";
import Constants from "../Utilities/Constants";
import _ from "lodash";
import ReactDOM from "react-dom";
import ModalSure from "./ModalSure";
import { deleteSite } from "../Axios/Axios";

export default class SiteCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displayOptions: false,
      site: this.props.site,
    };
    this.handleDisplayOptionToggle = () => {
      this.setState({
        displayOptions: !this.state.displayOptions,
      });
    };
    this.handleDisplayOptionCollapse = () => {
      this.setState({
        displayOptions: false,
      });
    };
    this.setRef = (node) => {
      this.ellipsisRef = node;
    };
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  deleteSite(param, siteId) {
    if (param) {
      deleteSite(siteId, (status, response) => {
        console.log(response);
        this.props.refresh();
      });
    }
  }

  handleClickOutside = (event) => {
    // const domNode = ReactDOM.findDOMNode(
    //   this.getElementsByClassName("notification-box")[0]
    // );

    if (!this.ellipsisRef || !this.ellipsisRef.contains(event.target)) {
      this.setState({
        displayOptions: false,
      });
    }
  };

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  render() {
    return (
      <div class="d-card l-d-card mb-20">
        <div class="d-card__header text-center" style={this.props.style} />
        <div class="d-card__body text-center">
          <div
            class="chart-header__action-box pull-right"
            // tabIndex="0"
            // onBlur={this.handleDisplayOptionCollapse}
            ref={this.setRef}
          >
            <span
              class="ellipsis open-task-option"
              onClick={this.handleDisplayOptionToggle}
            >
              <span class="ellipsis-dots" />
              <span class="ellipsis-dots" />
              <span class="ellipsis-dots" />
            </span>
            <div
              class="notification-box account-box task-options"
              style={{ display: this.state.displayOptions ? "block" : "none" }}
            >
              <div>
                <ModalSure
                  onAction={(param) => {
                    if (param) this.deleteSite(param, this.state.site.id);
                  }}
                >
                  <Link to="#">
                    <i class="fa fa-trash" />
                    Delete site
                  </Link>
                </ModalSure>
                <Link
                  to={_.replace(
                    Constants.ROUTES.EDIT_SITE,
                    /:id/g,
                    this.state.site.id
                  )}
                >
                  <i class="fa fa-edit" />
                  Edit site
                </Link>
              </div>
            </div>
          </div>
          <div class="d-card__p-image info-card__img">
            <img src={require("../assets/images/home.png")} />
          </div>
          <p class="d-card__name">{this.state.site.name}</p>
          <span class="d-card__hint">{this.state.site.url}</span>
          <p class="d-card__regular-text">
            {this.props.statusText
              ? this.props.statusText
              : this.state.site.subscribers + " Subscribers"}
          </p>
        </div>
        <div class="text-center d-card__footer">
          <Link
            to={{
              pathname: _.replace(
                this.props.buttonLink
                  ? this.props.buttonLink
                  : Constants.ROUTES.SITE_SEND_PUSH,
                /:id/g,
                this.state.site.id
              ),
              site: this.state.site,
            }}
            class="btn d-btn"
          >
            {this.props.buttonText ? this.props.buttonText : "View Site"}
          </Link>
        </div>
      </div>
    );
  }
}
