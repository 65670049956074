import React, { Component } from "react";
import Header from "../../common/Header";
import Footer from "../../common/Footer";
import { Link, Redirect } from "react-router-dom";
// import { isAuthorized } from '../Utilities/Auth';
import $ from "jquery";
import { logout } from "../../../Axios/Axios";

class Logout extends Component {
  constructor(props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  logout() {
    logout((status, response) => {
      if (status === "success") {
        localStorage.removeItem("token");
        localStorage.removeItem("roles");
        localStorage.removeItem("email");
        localStorage.removeItem("temail");
        localStorage.removeItem("addWelcomeNotification");
        localStorage.removeItem("tid");
        this.props.history.push("/");
      }
    });
  }

  componentDidMount() {
    this.logout();
  }
  render() {
    return <span />;
  }
}

export default Logout;
