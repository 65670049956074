import React, {Component} from 'react';
import Dashboard from '../../layouts/Dashboard'
import {Link} from 'react-router-dom'
import Constants from '../../../Utilities/Constants'
import _ from 'lodash'
import Input from '../../../components/Input'
import $ from 'jquery';


export default class SiteStat extends Component{
    
    constructor(props){
        super(props)
        this.state = {
            data : [],

        }
       
    }
      
      componentDidMount(){
     
       this.setState({
       });

    }

    
    render(){

        return(
            <Dashboard  {...this.props} match={this.props.match} history={this.props.history} pageTitle="Dashboard" menuActive={Constants.ROUTES.SITE_STAT} bodyClass="dashboard" >
               <div class="stats-container-2">
        				<div class="row">
	        				<div class="col-md-6 col-lg-3">
	        					<div class="d-stats-box l-d-stats-box">
	        						<h1 class="d-stats-box__count">3</h1>
	        						<p class="d-stats-box__text">Total Subscribers(Million)</p>
	        						<div class="d-stats-box__progress progress">
	        							 <div class="progress-bar bg-warning" role="progressbar" style={{width: "56%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
	        						</div>
	        					</div>
	        				</div>
	        				<div class="col-md-6 col-lg-3">
	        					<div class="d-stats-box l-d-stats-box">
	        						<h1 class="d-stats-box__count">20K</h1>
	        						<p class="d-stats-box__text">Push Notification in last hour</p>
	        						<div class="d-stats-box__progress progress">
	        							 <div class="progress-bar bg-info" role="progressbar" style={{width: "68%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
	        						</div>
	        					</div>
	        				</div>
	        				<div class="col-md-6 col-lg-3">
	        					<div class="d-stats-box l-d-stats-box">
	        						<h1 class="d-stats-box__count">80K</h1>
	        						<p class="d-stats-box__text">Pending Notifications</p>
	        						<div class="d-stats-box__progress progress">
	        							 <div class="progress-bar bg-danger" role="progressbar" style={{width: "25%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
	        						</div>
	        					</div>
	        				</div>
	        			</div>
        			</div>
            </Dashboard>
        )

        }
}