import React, { Component } from "react";
import AddSite from "../../layouts/AddSite";
import { Link } from "react-router-dom";
import Constants from "../../../Utilities/Constants";
import _ from "lodash";
import Input from "../../../components/Input";
import $ from "jquery";
import { verifyPixel } from "../../../Axios/Axios";
import { getSiteHelper, getSitesHelper } from "../../../Utilities/Helper";

let timer;
export default class VerifyPixel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      goToDash: false,
      site: {},
    };
    this.setTimer = this.setTimer.bind(this);
  }

  setTimer(siteId) {
    timer = setInterval(() => {
      verifyPixel(siteId, (status, response) => {
        if (status === "success") {
          this.setState({
            goToDash: true,
          });
          clearInterval(timer);
        }
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(timer);
  }

  componentDidMount() {
    if (this.state.site.name) {
      this.setTimer(this.state.site.id);
    }
    getSiteHelper(this, this.props);
  }

  componentWillUpdate(nextProps, nextState) {
    console.log(
      this.state.site,
      nextState.site,
      this.props.site,
      nextProps.site
    );
    if (this.state.site !== nextState.site) {
      this.setState(
        {
          site: nextState.site,
        },
        () => {
          this.setTimer(this.state.site.id);
        }
      );
    }
  }

  render() {
    return (
      <AddSite
        history={this.props.history}
        pageTitle="Add Site"
        menuActive={Constants.ROUTES.ADD_SITE_PIXEL_VERIFY}
        bodyClass="dashboard profile-dash"
        {...this.props}
      >
        <h3 class="">Checking Pixel Activity...</h3>
        <br />
        <br />
        <form class="d-form d-form-1" action={Constants.ROUTES.ADMIN_DASHBOARD}>
          {this.state.goToDash && (
            <div>
              <div class="form-group">
                <label for="">Congratulations! Site is sucessfully added</label>
              </div>
              <div class="form-group">
                <input
                  type="submit"
                  class="btn d-btn"
                  name=""
                  value="Go To Dashboard"
                />
              </div>
            </div>
          )}
        </form>
      </AddSite>
    );
  }
}
