import React from "react";
import ModalUI from "./ModalUI";
import TriggerModalSure from "../Triggers/TriggerModalSure";
import ReactDOM from "react-dom";
import { Link } from "react-router-dom";

export default class ModalDriverAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalTitle: "Are you sure?",
      showModal: false,
    };

    this.handleShowModal = this.handleShowModal.bind(this);
    this.handleModalToggle = this.handleModalToggle.bind(this);
    this.handleModalClose = this.handleModalClose.bind(this);
    this.handleAction = this.handleAction.bind(this);
  }

  handleAction(param) {
    this.props.onAction(param);
    this.handleModalClose();
  }

  handleShowModal(e) {
    e.preventDefault();
    // console.log('Allocate L2');
    this.setState({
      showModal: true,
    });
  }

  handleModalToggle() {
    this.setState({
      showModal: !this.state.showModal,
    });
  }

  handleModalClose() {
    this.setState({
      showModal: false,
    });
  }
  componentWillUpdate(nextProps, nextState) {}

  render() {
    return (
      <React.Fragment>
        <TriggerModalSure showModal={this.handleShowModal}>
          {this.props.children}
        </TriggerModalSure>
        {this.state.showModal &&
          ReactDOM.createPortal(
            <ModalUI
              title={this.state.modalTitle}
              show={this.state.showModal}
              size="medium"
              onClose={this.handleModalClose}
            >
              <div class="row">
                <div className="col-md-12">
                  <p className="mb-20 mt-20">
                    You cannot undo this action. Are you sure you want to delete
                    this site?
                  </p>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <button
                    className="btn btn-primary"
                    onClick={() => this.handleAction(true)}
                  >
                    <i class="fa fa-check" />
                    Yes
                  </button>
                  <button
                    className="pull-right btn btn-danger"
                    onClick={() => this.handleAction(false)}
                  >
                    <i class="fa fa-close" />
                    Cancel
                  </button>
                </div>
              </div>
            </ModalUI>,
            document.getElementById("root")
          )}
      </React.Fragment>
    );
  }
}
