import React, { Component } from "react";
import AddSite from "../../layouts/AddSite";
import { Link } from "react-router-dom";
import Constants from "../../../Utilities/Constants";
import _ from "lodash";
import Input from "../../../components/Input";
import $ from "jquery";
import { UnControlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/theme/neat.css";
import { getSiteHelper, getSitesHelper } from "../../../Utilities/Helper";
require("codemirror/mode/xml/xml");
require("codemirror/mode/javascript/javascript");

export default class InstallPixel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      script: "",
      site: {},
    };
  }

  getScript(siteId) {
    return (
      `<script>
      (function (w,o,t,a) {
          w[o] = w[o] || function () {
          (w[o].q = w[o].q || [ ]).push(arguments);
          };
          var s = t.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = a
          var x = t.getElementsByTagName('head')[0];
          x.appendChild(s);
      })(window, 'AYWEBPUSH', document, 'https://learncbse.arthism.com/sdk/webpush-min.js')
      AYWEBPUSH('site', '` +
      siteId +
      `');
  </script>`
    );
  }

  componentDidMount() {
    this.setState({});
    getSiteHelper(this, this.props);
  }

  componentWillUpdate(nextProps, nextState) {
    console.log(
      this.state.site,
      nextState.site,
      this.props.site,
      nextProps.site
    );
    if (this.state.site !== nextState.site) {
      this.setState({
        site: nextState.site,
        script: this.getScript(nextState.site.id),
      });
    }

    // if (this.props.site !== nextProps.site) {
    //   this.setState({
    //     site: nextProps.site,
    //     script: nextProps.site ? this.getScript(nextProps.site.id) : "",
    //   });
    // }
  }

  render() {
    return (
      <AddSite
        history={this.props.history}
        pageTitle="Add Site"
        menuActive={Constants.ROUTES.ADD_SITE_PIXEL_INSTALL}
        bodyClass="dashboard profile-dash"
        {...this.props}
      >
        <h3 class="">Install Pixel</h3>
        <br />
        <br />
        <form class="d-form d-form-1" action="#">
          <div class="form-group">
            <label for="">
              1). Unzip and upload the files in the SDK to site root folder
            </label>
            <br />
            <p>
              <a
                href="https://learncbse.arthism.com/sdk/sdk.zip"
                target="new"
                class="btn d-btn d-btn-default"
              >
                Download SDK
              </a>
            </p>
          </div>
          <br />
          <div class="form-group">
            <label for="">
              2). If you haven't already, add this code to the &lt;head&gt;
              section on all pages of your site that users can subscribe to.
            </label>
            <CodeMirror
              value={this.state.script}
              options={{
                mode: "javascript",
                theme: "neat",
                lineNumbers: true,
                readOnly: true,
              }}
              onChange={(editor, data, value) => {
                console.log("uncontrolled", { value });
              }}
            />
          </div>
          <div class="form-group">
            <input
              type="submit"
              class="btn d-btn"
              name=""
              onClick={() =>
                this.props.history.push(
                  _.replace(
                    Constants.ROUTES.ADD_SITE_PIXEL_VERIFY,
                    /:id/g,
                    this.props.match.params.id
                  )
                )
              }
              value="Added Pixel to Header"
            />
          </div>
        </form>
      </AddSite>
    );
  }
}
