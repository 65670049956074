import React, {Component} from 'react';
import { Link, Redirect } from 'react-router-dom';
// import { FormGroup, Label, Input } from 'reactstrap';
// import axios from 'axios';
import $ from 'jquery';


class Footer extends Component{

    constructor(props){
        super(props)
        this.state={
            
        }

    }

  

    componentDidMount(){
    }
    render(){
       
        return(
            <footer class="">
                
              
            </footer>
        );
    }
}

export default Footer;