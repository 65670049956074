import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/font-awesome.min.css';
import './assets/css/dropdown.css';
import './assets/css/checkbox.css';
import './assets/css/animate.css';
import './assets/icon-font/flaticon.css';
import './assets/css/transition.css';
// import 'react-table/react-table.css';
// import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/css/ui-common.css';
import './assets/css/learncbse.css';



import App from './App';
import * as serviceWorker from './serviceWorker';
// import 'react-table/react-table.css';


ReactDOM.render(<App />, document.getElementById('root'));
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
