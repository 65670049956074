import React, { Component } from "react";
import Dashboard from "../../layouts/Dashboard";
import { Link } from "react-router-dom";
import Constants from "../../../Utilities/Constants";
import _ from "lodash";
import Input from "../../../components/Input";
import $ from "jquery";
import classNames from "classnames";
import { formatSiteUrl } from "../../../Utilities/Helper";
import moment from "moment";

import { sendNotification } from "../../../Axios/Axios";
import { SiteContext } from "../../../Contexts/SiteContext";

let inititalState = {};
export default class Send extends Component {
  constructor(props) {
    super(props);

    console.log(props.location.notification, props.site);

    let notification = props.location.notification;

    let site = this.props.location.site
      ? this.props.location.site
      : this.props.site;

    inititalState = {
      data: [],
      laterDateTime: moment()
        .add(1, "hours")
        .toDate(),
      siteName: site ? site.name : "",
      siteURL: site ? formatSiteUrl(site.url) : "",
      previewURL: "",
      iconPreviewURL: "",
      imageURL: notification ? notification.image : "",
      iconImageURL: notification ? notification.image : "",
      files: [],
      defaultNotificationTitle: "Title",
      defaultNotificationMessage: "Message",
      selectedTab: "macos",
      showSendLater: false,
      title: notification ? notification.title : "",
      message: notification ? notification.message : "",
      launchURL: notification ? notification.launch_url : "",
      errors: {},
      image: "",
      icon: "",
    };

    console.log(site);

    this.state = inititalState;

    this.handleOnchange = this.handleOnchange.bind(this);
    this.sendNotification = this.sendNotification.bind(this);
    this.handleOnDrop = this.handleOnDrop.bind(this);
    this.handleOnDropIcon = this.handleOnDropIcon.bind(this);
    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleSendLater = this.handleSendLater.bind(this);
    this.handleRemoveImage = this.handleRemoveImage.bind(this);
    this.handleRemoveIcon = this.handleRemoveIcon.bind(this);
  }

  handleRemoveImage() {
    this.setState({
      previewURL: "",
      files: [],
    });
  }

  handleRemoveIcon() {
    this.setState({
      iconPreviewURL: "",
      image: "",
    });
  }

  handleTabClick(value) {
    this.setState({
      selectedTab: value,
    });
  }
  handleSendLater(el, value) {
    this.setState({
      showSendLater: value.checked,
    });
  }

  handleOnDrop(files) {
    console.log(files, files[0]);
    this.setState({
      files,
      image: files[0],
      previewURL: URL.createObjectURL(files[0]),
    });
    // const reader = new FileReader();
    // reader.onload = (event) => {
    //   console.log(event.target.result);
    // };
    // reader.readAsDataURL(files[0]);
  }

  handleOnDropIcon(files) {
    console.log(files, files[0]);
    this.setState({
      icon: files[0],
      iconPreviewURL: URL.createObjectURL(files[0]),
    });
    // const reader = new FileReader();
    // reader.onload = (event) => {
    //   console.log(event.target.result);
    // };
    // reader.readAsDataURL(files[0]);
  }

  handleOnchange(name, value) {
    console.log(name, value);
    this.setState({
      [name]: value,
    });
  }

  sendNotification(e) {
    e.preventDefault();
    let obj = {
      image: this.state.image ? this.state.image : this.state.imageURL,
      icon: this.state.icon ? this.state.icon : this.state.iconImageURL,
      send_later: this.state.showSendLater ? 1 : 0,
      send_date_time: moment(
        this.state.laterDateTime,
        "MMMM D, YYYY h:mm A"
      ).format("Y-MM-DD HH:mm:ss"),
      launchURL: this.state.launchURL,
    };
    console.log(
      this.props.match.params.id,
      this.state.title,
      this.state.message
    );
    sendNotification(
      this.props.match.params.id,
      this.state.title,
      this.state.message,
      obj,
      (status, response) => {
        if (status === "success") {
          this.setState({
            status: "Notification sent successfully",
          });
          this.setState(inititalState);
        } else if (status === "validation_error") {
          console.log(response);
          this.setState({
            errors: response,
          });
        }
      }
    );
  }

  componentDidMount() {
    this.setState({});
  }

  componentWillUpdate(nextProps, nextState) {
    console.log(this.props.site, nextProps.site, this.props.location.site);
    if (this.props.site !== nextProps.site) {
      this.setState({
        siteName: nextProps.site.name,
        siteURL: formatSiteUrl(nextProps.site.url),
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log(
      nextProps.location.notification,
      this.props.site,
      nextProps.site,
      nextProps.match.params.id,
      nextProps.location.site
    );
    if (
      nextProps.location.site &&
      this.props.site !== nextProps.location.site
    ) {
      this.setState({
        siteName: nextProps.location.site.name,
        siteURL: formatSiteUrl(nextProps.location.site.url),
      });
    }
  }

  render() {
    const files = this.state.files.map((file) => (
      <li key={file.name}>
        <div key={file.name}>
          <div>
            <img src={URL.createObjectURL(file)} width="100%" />
          </div>
        </div>
      </li>
    ));
    return (
      <SiteContext.Consumer>
        {({ activeSites, defaultSite }) => (
          <Dashboard
            {...this.props}
            match={this.props.match}
            history={this.props.history}
            pageTitle="Dashboard"
            menuActive={Constants.ROUTES.SITE_SEND_PUSH}
            bodyClass="dashboard"
          >
            <div class="d-container stats-container-2">
              <div class="row">
                <div class="col-md-5 col-5 nopadding">
                  <div class="d-box add-project-box">
                    <div class="chart-header__text">
                      <h3 class="chart-heading">Send Notification</h3>
                    </div>
                    <br />
                    <span className={this.state.status ? "success" : ""}>
                      {this.state.status}
                    </span>
                    <br />
                    <form class="d-form">
                      <div class="nopadding form-group col-md-12">
                        <label for="inputEmail4">Title</label>
                        <Input
                          type="text"
                          name="title"
                          onChange={this.handleOnchange}
                          class="form-control"
                          id="inputEmail4"
                          placeholder="Title"
                          value={this.state.title}
                          errortext={this.state.errors.title}
                        />
                      </div>
                      <div class="nopadding form-group col-md-12">
                        <label for="">Message</label>
                        <Input
                          type="textarea"
                          name="message"
                          onChange={this.handleOnchange}
                          class="form-control"
                          placeholder="Message"
                          value={this.state.message}
                          errortext={this.state.errors.message}
                        />
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-8 nopadding posrel">
                          <label for="">Icon Image</label>
                          <Input
                            type="text"
                            class="form-control"
                            name="iconImageURL"
                            onChange={this.handleOnchange}
                            value={this.state.iconImageURL}
                            placeholder={
                              !this.state.iconPreviewURL
                                ? "Enter URL or Upload an Icon (Min: 192 x 192)"
                                : ""
                            }
                            disabled={
                              this.state.iconPreviewURL ? "disabled" : null
                            }
                          />
                          {this.state.iconPreviewURL && (
                            <span class="preview-image">
                              <img
                                src={this.state.iconPreviewURL}
                                alt=""
                                srcset=""
                                height="30"
                              />
                            </span>
                          )}
                        </div>
                        {!this.state.iconImageURL && (
                          <div class="form-group col-md-3 nopadding">
                            <label for="">&nbsp;</label>
                            {!this.state.iconPreviewURL && (
                              <Input
                                type="dropzone"
                                onDrop={this.handleOnDropIcon}
                              >
                                <button
                                  type="button"
                                  class="btn btn-primary btn--merged"
                                >
                                  <i className="fa fa-plus" /> Upload Icon
                                </button>
                              </Input>
                            )}
                            {this.state.iconPreviewURL && (
                              <button
                                type="button"
                                class="btn btn-gray btn--merged"
                                onClick={this.handleRemoveIcon}
                              >
                                <i className="fa fa-close" /> Remove Icon
                              </button>
                            )}
                          </div>
                        )}
                        {this.state.iconImageURL && (
                          <div class="form-group col-md-3 nopadding">
                            <span class="preview-image">
                              <img
                                src={this.state.iconImageURL}
                                alt=""
                                srcset=""
                                height="30"
                              />
                            </span>
                          </div>
                        )}
                        <div className="clearfix" />
                      </div>
                      <div class="form-row">
                        <div class="form-group col-md-8 nopadding posrel">
                          <label for="">Banner Image</label>
                          <Input
                            type="text"
                            class="form-control"
                            name="imageURL"
                            onChange={this.handleOnchange}
                            value={this.state.imageURL}
                            placeholder={
                              !this.state.previewURL
                                ? "Enter URL or Upload an Image"
                                : ""
                            }
                            disabled={this.state.previewURL ? "disabled" : null}
                          />
                          {this.state.previewURL && (
                            <span class="preview-image">
                              <img
                                src={this.state.previewURL}
                                alt=""
                                srcset=""
                                height="30"
                              />
                            </span>
                          )}
                        </div>
                        {!this.state.imageURL && (
                          <div class="form-group col-md-3 nopadding">
                            <label for="">&nbsp;</label>
                            {!this.state.previewURL && (
                              <Input type="dropzone" onDrop={this.handleOnDrop}>
                                <button
                                  type="button"
                                  class="btn btn-primary btn--merged"
                                >
                                  <i className="fa fa-plus" /> Upload Image
                                </button>
                              </Input>
                            )}
                            {this.state.previewURL && (
                              <button
                                type="button"
                                class="btn btn-gray btn--merged"
                                onClick={this.handleRemoveImage}
                              >
                                <i className="fa fa-close" /> Remove Image
                              </button>
                            )}
                          </div>
                        )}
                        {this.state.imageURL && (
                          <div class="form-group col-md-3 nopadding">
                            <span class="preview-image">
                              <img
                                src={this.state.imageURL}
                                alt=""
                                srcset=""
                                height="30"
                              />
                            </span>
                          </div>
                        )}
                        <div className="clearfix" />
                        {/* <aside>
                      <h4>Files</h4>
                      <ul>{files}</ul>
                    </aside> */}
                      </div>
                      <div class="nopadding form-group col-md-12">
                        <label for="">Launch URL</label>
                        <Input
                          type="text"
                          class="form-control"
                          placeholder="http://bit/ly/XXXXX"
                          name="launchURL"
                          onChange={this.handleOnchange}
                          value={this.state.launchURL}
                          errortext={this.state.errors.launch_url}
                        />
                      </div>
                      <div className="clearfix" />
                      <br />
                      <div className="row">
                        <hr />
                        <div class="form-group col-md-3 nopadding">
                          <label for="">Send Later</label>
                          <div class="toggle-group">
                            {/* <label>Send Later</label> */}
                            <Input
                              type="checkbox-radio"
                              toggle="true"
                              elementonly="true"
                              onChange={this.handleSendLater}
                              errortext={this.state.errors.send_later}
                              checked={this.state.showSendLater}
                            />
                          </div>
                        </div>
                        {this.state.showSendLater && (
                          <div className="form-group col-md-9">
                            <label htmlFor="">&nbsp;</label>
                            <Input
                              type="datepicker"
                              name="laterDateTime"
                              showTimeSelect
                              // showTimeSelectOnly
                              timeFormat="h:mm aa"
                              startDate={this.state.laterDateTime}
                              timeIntervals={1}
                              timeCaption="Time"
                              onChange={this.handleOnchange}
                              dateFormat="MMMM d, yyyy h:mm aa"
                              className="form-control d-datepicker"
                              placeholder="Select date"
                              elementonly="true"
                              preventOpenOnFocus={true}
                              errortext={this.state.errors.send_date_time}
                            />
                          </div>
                        )}
                      </div>

                      <div class="col-md-12 nopadding">
                        <button
                          type="submit"
                          class="btn btn-primary"
                          onClick={this.sendNotification}
                        >
                          Send
                        </button>
                      </div>
                    </form>
                    <div class="clearfix" />
                  </div>
                </div>
                <div class="col-md-6 col-6">
                  {/* <img src={require('../../../assets/images/sample-notification.png')} alt="" /> */}
                  <ul
                    class="nav nav-tabs justify-content-center d-tabs"
                    id="myTab"
                    role="tablist"
                  >
                    <li class="nav-item">
                      <Link
                        to="#"
                        onClick={() => this.handleTabClick("macos")}
                        className={classNames({
                          "nav-link btn d-nav-link d-nav-link--left": true,
                          active: this.state.selectedTab === "macos",
                        })}
                      >
                        MacOS
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        to="#"
                        onClick={() => this.handleTabClick("windows")}
                        className={classNames({
                          "nav-link btn d-nav-link": true,
                          active: this.state.selectedTab === "windows",
                        })}
                      >
                        Windows OS
                      </Link>
                    </li>
                    <li class="nav-item">
                      <Link
                        to="#"
                        onClick={() => this.handleTabClick("android")}
                        className={classNames({
                          "nav-link btn d-nav-link d-nav-link--right": true,
                          active: this.state.selectedTab === "android",
                        })}
                      >
                        Android
                      </Link>
                    </li>
                  </ul>
                  <div className="notification-preview">
                    {this.state.selectedTab === "macos" && (
                      <div className="macos-notification-wrapper">
                        <div className="macos-notification-container">
                          <div className="macos-notification-icon">
                            <svg viewBox="0 0 48 48" class="sc-ccLTTT jnqfSK">
                              <circle cx="24" cy="24" r="19" fill="#4CAF50" />
                              <path
                                fill="#FFC107"
                                d="M24 5v19l8 4-8.8 15h.8c10.5 0 19-8.5 19-19S34.5 5 24 5z"
                              />
                              <path
                                fill="#F44336"
                                d="M16 28l8-13h16.7C37.5 9 31.2 5 24 5c-6.7 0-12.6 3.5-16 8.8L16 28z"
                              />
                              <path fill="#DD2C00" d="M8 13.8L16 28l5-1" />
                              <path fill="#F9A825" d="M40.6 15H24l-1.5 4.8" />
                              <path
                                fill="#558B2F"
                                d="M23.2 43L32 28l-3.8-3.1"
                              />
                              <circle cx="24" cy="24" r="9" fill="#FFF" />
                              <circle cx="24" cy="24" r="7" fill="#2196F3" />
                            </svg>
                          </div>
                          <div className="macos-notification-content">
                            <div className="macos-notification-content-title">
                              {this.state.title
                                ? this.state.title
                                : this.state.defaultNotificationTitle}
                            </div>
                            <div className="macos-notification-content-site">
                              {this.state.siteURL
                                ? this.state.siteURL
                                : "example.com"}
                            </div>
                            <div className="macos-notification-content-desc">
                              {this.state.message
                                ? this.state.message
                                : this.state.defaultNotificationMessage}
                            </div>
                          </div>
                          <div className="macos-notification-image">
                            {(this.state.previewURL || this.state.imageURL) && (
                              <img
                                src={
                                  this.state.previewURL
                                    ? this.state.previewURL
                                    : this.state.imageURL
                                      ? this.state.imageURL
                                      : ""
                                }
                                alt=""
                                srcset=""
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.selectedTab === "android" && (
                      <div className="android-notification-container">
                        <div
                          className={classNames({
                            "android-notification": true,
                            "with-image":
                              !!this.state.previewURL || !!this.state.imageURL,
                          })}
                        >
                          <div className="android-notification-top">
                            <svg viewBox="0 0 1024 1024" class="">
                              <path
                                stroke="inherit"
                                d="M258.278 446.542l-146.532-253.802c93.818-117.464 238.234-192.74 400.254-192.74 187.432 0 351.31 100.736 440.532 251h-417.77c-7.504-0.65-15.092-1-22.762-1-121.874 0-224.578 83.644-253.722 196.542zM695.306 325h293.46c22.74 57.93 35.234 121.004 35.234 187 0 280.826-226.1 508.804-506.186 511.926l209.394-362.678c29.48-42.378 46.792-93.826 46.792-149.248 0-73.17-30.164-139.42-78.694-187zM326 512c0-102.56 83.44-186 186-186s186 83.44 186 186c0 102.56-83.44 186-186 186s-186-83.44-186-186zM582.182 764.442l-146.578 253.878c-246.532-36.884-435.604-249.516-435.604-506.32 0-91.218 23.884-176.846 65.696-251.024l209.030 362.054c41.868 89.112 132.476 150.97 237.274 150.97 24.3 0 47.836-3.34 70.182-9.558z"
                              />
                            </svg>
                            <span>
                              Chrome •{" "}
                              {this.state.siteURL
                                ? this.state.siteURL
                                : "example.com"}
                            </span>
                          </div>
                          <div className="android-notification-content">
                            <div className="android-notification-content-container">
                              <div style={{ lineHeight: "1px" }}>
                                <div className="android-notification-content-title">
                                  {this.state.title
                                    ? this.state.title
                                    : this.state.defaultNotificationTitle}
                                </div>
                                <div className="android-notification-content-desc">
                                  {this.state.message
                                    ? this.state.message
                                    : this.state.defaultNotificationMessage}
                                </div>
                              </div>
                            </div>
                            {(this.state.previewURL || this.state.imageURL) && (
                              <img
                                src={
                                  this.state.previewURL
                                    ? this.state.previewURL
                                    : this.state.imageURL
                                      ? this.state.imageURL
                                      : ""
                                }
                                alt=""
                                srcset=""
                                class="android-notification-image"
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    {this.state.selectedTab === "windows" && (
                      <div className="windows-notification-container">
                        {(this.state.previewURL || this.state.imageURL) && (
                          <img
                            src={
                              this.state.previewURL
                                ? this.state.previewURL
                                : this.state.imageURL
                                  ? this.state.imageURL
                                  : ""
                            }
                            alt=""
                            class="w-100"
                          />
                        )}
                        <div className="windows-notification">
                          <div className="windows-notification-left">
                            <div className="windows-notification-icon">
                              <svg viewBox="0 0 48 48" class="">
                                <circle cx="24" cy="24" r="19" fill="#4CAF50" />
                                <path
                                  fill="#FFC107"
                                  d="M24 5v19l8 4-8.8 15h.8c10.5 0 19-8.5 19-19S34.5 5 24 5z"
                                />
                                <path
                                  fill="#F44336"
                                  d="M16 28l8-13h16.7C37.5 9 31.2 5 24 5c-6.7 0-12.6 3.5-16 8.8L16 28z"
                                />
                                <path fill="#DD2C00" d="M8 13.8L16 28l5-1" />
                                <path fill="#F9A825" d="M40.6 15H24l-1.5 4.8" />
                                <path
                                  fill="#558B2F"
                                  d="M23.2 43L32 28l-3.8-3.1"
                                />
                                <circle cx="24" cy="24" r="9" fill="#FFF" />
                                <circle cx="24" cy="24" r="7" fill="#2196F3" />
                              </svg>
                            </div>
                          </div>
                          <div className="windows-notification-right">
                            <div class="windows-notification-right-title">
                              {this.state.title
                                ? this.state.title
                                : this.state.defaultNotificationTitle}
                            </div>
                            <div class="windows-notification-right-desc">
                              {this.state.message
                                ? this.state.message
                                : this.state.defaultNotificationMessage}
                            </div>
                            <div class="windows-notification-right-brand">
                              Google Chrome •{" "}
                              {this.state.siteURL
                                ? this.state.siteURL
                                : "example.com"}
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Dashboard>
        )}
      </SiteContext.Consumer>
    );
  }
}
