import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import _ from 'lodash'
import Constants from '../Utilities/Constants'
function checkToken(){
    let token = localStorage.getItem('token');
    return token?true:false;
}

function getRoles(){
    let roles = localStorage.getItem('roles');
    return roles?roles:false;
}

function isAuthorized(){
    let token = checkToken();
    if(token){
        return true;
    }else{
        
        return false;
    }
}

function hasPermission(moduleName){
    let roles = getRoles();
    if(roles){
        let allowedModules = Constants.ROLES_PERMISSIONS['USER_'+roles.toUpperCase()];
        if(_.indexOf(allowedModules, moduleName) !== -1){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
}

function hasRole(roleName){
    let roles = getRoles();
    if(roles){
        if(roles == roleName){
            return true;
        }else{
            return false;
        }
    }else{
        return false;
    }
}

function guard(userRoles){
   let matchedRole = _.filter(userRoles, (role) => {
        return hasRole(role)
    });
    
    if(matchedRole.length > 0 ){
        return true;
    }else{
        window.location = '/dashboard'
    }
}



export { checkToken, isAuthorized, hasPermission, guard, hasRole };