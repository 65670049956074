import React, {Component} from 'react';
import Input from '../../components/Input'
import { Link, Redirect } from 'react-router-dom';
// import { FormGroup, Label, Input } from 'reactstrap';
import classNames from 'classnames'
import $ from 'jquery';
import Constants from '../../Utilities/Constants'
import _ from 'lodash'


class HeaderLoggedIn extends Component{

    constructor(props){
        super(props);
        this.state = {
            name : '',
            navIcon : this.props.navIcon?this.props.navIcon:'bar.png',
            navShowMenu : false
        }
        this.handleNewScenario = this.handleNewScenario.bind(this);
        this.navToggleHover = this.navToggleHover.bind(this);
    }

    componentDidMount(){
        this.setState({
            'name' : localStorage.getItem('tname')
        })
        // $("body").on('click', ".dropdown-toggle", function(e){
        //     e.stopPropagation();
        //     console.log("click");
        //     $(this).next(".dropdown-menu").toggle();
        // }).on('click',function(e){
        //     // e.stopPropagation();
        //     if(!$(this).hasClass('dropdown-toggle')){
        //         $(".dropdown-menu").hide();
        //     }
        // });
    }
    navToggleHover(param, e){
        console.log(param, e);
        this.setState({
            navShowMenu:!this.state.navShowMenu
        });
        
    }

    handleNewScenario(e){
        console.log();
        e.preventDefault();
     

    }
    render(){
       console.log("props", this.props);
       var menu2Class = (text) => { 
           return  classNames({
            active: this.props.menu2Active==text,
      });
      
    }
    var menu3Class = (text) => { 
        return  classNames({
        active: this.props.menu3Active==text,
    });
   
 }
        return(
            <div class="hl-header"> 
            <div class="top-header">
                <div class="row">
                    <div class="container">
                        <div class="col-md-5 col-xs-4 nopadding">
                            <div class="left-header">
                                <ul class="inline">
                                    <li className={menu2Class('ghq')}>
                                        <Link to="ghq-guidance">2020 GHQ Guidance</Link>
                                    </li>
                                    <li  className={menu2Class('scenario')}>
                                        <Link to="scenario">Scenarios</Link>
                                    </li>
                                </ul>
                            </div> 
                        </div>
                        <div class="col-md-3 col-xs-2">
                            <div class="center-header">
                                
                                </div>
                            </div>
                            <div class="col-md-4 col-xs-6 nopadding">
                                <div class="right-header">
                                    <ul>
                                        <li>
                                       
                                        </li>
                                        <li>
                                            <div class="dropdown more-dropdown more-dropdown-user">
                                                <Link to="##" data-toggle="dropdown" class="dropdown-toggle"><img src="" />}  />
                                            </Link>
                                                <div class="dropdown-menu">
                                                    <div class="more-dropdown-head">
                                                        <ul class="inline inline-mr-5 mr-10 ml-10">
                                                            <li class="text-bold">John Smith</li>
                                                        </ul>
                                                        <ul class="inline inline-mr-5 mr-10 ml-10">
                                                            <li class="text-muted"> Last Login</li>
                                                            <li>5:30 PM, Wed, 05/10/2019</li>
                                                        </ul>
                                                        <ul class="inline inline-mr-5 mr-10 ml-10">
                                                            <li>
                                                                <Link to="terms.php">Terms of Use</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="faqs.php">FAQs</Link>
                                                            </li>
                                                            <li>
                                                                <Link to="">Change Password</Link>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div class="more-dropdown-body">
                                                        <div class="row">
                                                            <div class="col-md-12 nopadding">
                                                                <ul class="ghq-dropdown ghq-dropdown-new">
                                                                    <li>
                                                                        <Link to="">New Scenario from GHQ Guidance</Link> </li>
                                                                    <li>
                                                                        <Link to="">New Scenario From Scratch</Link>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                    <div class="more-dropdown-footer">
                                                        <div class="text-center">
                                                            <ul class="inline">
                                                                <li>
                                                                    <Link to="/logout" class="text-white"><img src="" alt="" /> Logout</Link>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
        
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        </div>
                    </div>
                   { this.props.subMenu && 
                    <div class="nav-sub-menu">
                        <div class="row">
                            <div class="container ">
                            <div class="col-md-12 nopadding">
                                <ul class="inline p-5 mb-0">
                                    <li className={menu3Class('level1')}>
                                        <Link to="/dashboard">Level 1</Link>
                                    </li>
                                    <li className={menu3Class('level2')}>
                                        <Link to="/l2">Level 2</Link>
                                    </li>
                                    <li className={menu3Class('details')}>
                                        <Link to="ghqg-details.php">Details</Link>
                                    </li>
                                </ul>
                                { this.props.subMenuRight && 
                                <ul class="pull-right inline inline-tabs p-5 mb-0">
                                    <li class="<?php echo !empty($menu3Active)?'active':'' ?>">
                                        <Link to="#">FC Vs Lev2 Opt</Link>
                                    </li>
                                    <li class="<?php echo !empty($menu3Active)&&$menu3Active=='level2'?'active':'' ?>">
                                        <Link to="#">Lev1 Opt Vs Lev2 Opt</Link>
                                    </li>
                                </ul>
                                }
                                { this.props.subMenuRight2 && 
                                <ul class="pull-right inline p-5 mb-0">
                                    <li class="<?php echo !empty($menu4Active)?'active':'' ?>">
                                    <Input type="select-s" 
                                        placeholder='Select Filter'
                                        selection options={this.props.filterOptions} 
                                        elementOnly={true}
                                        className=" search  nav-select"
                                        />
                                    
                                    </li>
                                    <li class="text-bold m-0">Vs</li>
                                    <li class="<?php echo !empty($menu4Active)&&$menu4Active=='level2'?'active':'' ?>">
                                    <Input type="select-s" 
                                        placeholder='Select Filter'
                                        selection options={this.props.filterOptions} 
                                        elementOnly={true}
                                        className=" search  nav-select"
                                        />
                                    </li>
                                </ul>
                                }
                            </div>
                            </div>
                        </div>
                    </div>
                   }
        
        </div>
        );
    }
}

export default HeaderLoggedIn;