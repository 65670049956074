import React, { Component } from "react";
import AddSite from "../../layouts/AddSite";
import { Link } from "react-router-dom";
import Constants from "../../../Utilities/Constants";
import _ from "lodash";
import Input from "../../../components/Input";
import $ from "jquery";
import {
  addWelcomeNotification,
  updateWelcomeNotification,
} from "../../../Axios/Axios";
import { getSiteHelper, getSitesHelper } from "../../../Utilities/Helper";

export default class WelcomeNotification extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      title: "",
      message: "",
      site: {},
    };

    this.handleOnchange = this.handleOnchange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleOnchange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let message = this.state.message;
    let title = this.state.title;
    let error = false;
    console.log(message, title);
    if (title.length < 1) {
      this.setState({
        error_title: "Notification title is requried",
      });
      error = true;
    } else {
      this.setState({
        error_title: undefined,
      });
    }

    if (message.length < 1) {
      this.setState({
        error_message: "Notification message is requried",
      });
      error = true;
    } else {
      this.setState({
        error_message: undefined,
      });
    }

    if (error) {
      return false;
    }
    let call = this.state.site.welcome_notification
      ? updateWelcomeNotification
      : addWelcomeNotification;

    call(this.props.match.params.id, title, message, (status, response) => {
      console.log(status, response);
      if (status === "success") {
        console.log(response);
        localStorage.setItem(
          "addWelcomeNotification",
          JSON.stringify(response)
        );
        this.props.history.push(
          _.replace(
            Constants.ROUTES.ADD_SITE_PIXEL_INSTALL,
            /:id/g,
            this.props.match.params.id
          )
        );
      } else if (status === "validation_error") {
        // console.log(response);
        _.each(response, (index, error) => {
          let errorKey = "error_" + error;
          // console.log(errorKey, index);
          this.setState({
            [errorKey]: index.toString(),
          });
        });
      }
    });
  }

  componentDidMount() {
    this.setState({
      data: [],
      title: "",
      message: "",
    });
    getSiteHelper(this, this.props);
  }

  componentWillUpdate(nextProps, nextState) {
    console.log(
      this.state.site,
      nextState.site,
      this.props.site,
      nextProps.site
    );
    if (this.state.site !== nextState.site) {
      this.setState({
        site: nextState.site,
        title: nextState.site.welcome_notification
          ? nextState.site.welcome_notification.title
          : "",
        message: nextState.site.welcome_notification
          ? nextState.site.welcome_notification.message
          : "",
      });
    }

    // if (this.props.site !== nextProps.site) {
    //   this.setState({
    //     site: nextProps.site,
    //   });
    // }
  }

  render() {
    return (
      <AddSite
        history={this.props.history}
        pageTitle="Add Site"
        menuActive={Constants.ROUTES.ADD_SITE_WELCOME_NOTIF}
        bodyClass="dashboard profile-dash"
        {...this.props}
      >
        <h3 class="">Welcome Notification</h3>
        <br />
        <br />
        <form class="d-form d-form-1" action="#">
          <div class="form-group">
            <label for="">Title</label>
            <Input
              elementonly="true"
              type="text"
              class="form-control"
              onChange={this.handleOnchange}
              name="title"
              placeholder="Welcome to our Website"
              value={this.state.title}
            />
          </div>
          <div class="form-group">
            <label for="">Message</label>
            <Input
              elementonly="true"
              type="textarea"
              class="form-control"
              onChange={this.handleOnchange}
              name="message"
              placeholder="Thanks for subscribing"
              value={this.state.message}
            />
          </div>
          <div class="form-group">
            <input
              type="submit"
              class="btn d-btn"
              name=""
              value="Next"
              onClick={this.handleSubmit}
            />
          </div>
        </form>
      </AddSite>
    );
  }
}
