import React, { Component } from "react";
import HeaderLoggedIn from "../common/HeaderLoggedIn";
import Footer from "../common/Footer";
// import AdminMenu from '../admin/AdminMenu'
import { Link, Redirect } from "react-router-dom";
// import { isAuthorized } from '../Utilities/Auth';
import $ from "jquery";
import classNames from "classnames";
import Constants from "../../Utilities/Constants";
import _ from "lodash";

class AddSite extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {}
  render() {
    // if (isAuthorized()) {
    //     // return <Redirect to='/' />
    // }
    var menuClass = (text) => {
      return classNames({
        active: this.props.menuActive == text,
      });
    };

    return (
      <div className={this.props.bodyClass}>
        <div class="big-container">
          <div class="d-main-container">
            <div class="container">
              <br />
              <br />
              <div class="sidebar-wrapper" />
              <div class="left-profile-tabs ">
                <ul>
                  <li className={menuClass(Constants.ROUTES.ADD_SITE)}>
                    <Link
                      to={
                        this.props.match.params.id
                          ? _.replace(
                              Constants.ROUTES.EDIT_SITE,
                              /:id/g,
                              this.props.match.params.id
                            )
                          : Constants.ROUTES.ADD_SITE
                      }
                    >
                      <i class="fa fa-dashboard" />
                      Site Setup
                    </Link>
                  </li>
                  <li
                    className={menuClass(
                      Constants.ROUTES.ADD_SITE_WELCOME_NOTIF
                    )}
                  >
                    <Link
                      to={
                        this.props.match.params.id
                          ? _.replace(
                              Constants.ROUTES.ADD_SITE_WELCOME_NOTIF,
                              /:id/g,
                              this.props.match.params.id
                            )
                          : ""
                      }
                      style={
                        !this.props.match.params.id
                          ? { pointerEvents: "none" }
                          : null
                      }
                    >
                      <i class="fa fa-bell" />
                      Welcome Notification
                    </Link>
                  </li>
                  <li
                    className={menuClass(
                      Constants.ROUTES.ADD_SITE_PIXEL_INSTALL
                    )}
                  >
                    <Link
                      to={
                        this.props.match.params.id
                          ? _.replace(
                              Constants.ROUTES.ADD_SITE_PIXEL_INSTALL,
                              /:id/g,
                              this.props.match.params.id
                            )
                          : ""
                      }
                      style={
                        !this.props.match.params.id
                          ? { pointerEvents: "none" }
                          : null
                      }
                    >
                      <i class="fa fa-download" />
                      Install Pixel
                    </Link>
                  </li>
                  <li
                    className={menuClass(
                      Constants.ROUTES.ADD_SITE_PIXEL_VERIFY
                    )}
                  >
                    <Link
                      to={
                        this.props.match.params.id
                          ? _.replace(
                              Constants.ROUTES.ADD_SITE_PIXEL_VERIFY,
                              /:id/g,
                              this.props.match.params.id
                            )
                          : ""
                      }
                      style={
                        !this.props.match.params.id
                          ? { pointerEvents: "none" }
                          : null
                      }
                    >
                      <i class="fa fa-check" />
                      Activate
                    </Link>
                  </li>
                </ul>
              </div>
              <div class="profile-main-container">
                <div class="d-box">
                  <div class="tab-2">{this.props.children}</div>
                </div>
              </div>
            </div>
          </div>
          <div class="d-sidebar-right" />
        </div>
      </div>
    );
  }
}

export default AddSite;
