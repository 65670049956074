import React, { Component } from "react";
import AddSite from "../../layouts/AddSite";
import { Link } from "react-router-dom";
import Constants from "../../../Utilities/Constants";
import _ from "lodash";
import Input from "../../../components/Input";
import $ from "jquery";
import { addSite, getSite, updateSite } from "../../../Axios/Axios";
import { getSiteHelper, getSitesHelper } from "../../../Utilities/Helper";

export default class SiteSetup extends Component {
  constructor(props) {
    super(props);
    let site = localStorage.getItem("addSite");
    this.state = {
      data: [],
      name: "",
      url: "",
      addSite: site && !this.props.match.params.id ? JSON.parse(site) : "",
      site: site ? JSON.parse(site).site : {},
      name: "",
      url: "",
    };

    console.log(this.props.site, site);

    this.handleOnchange = this.handleOnchange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.getSite = this.getSite.bind(this);
    this.getSite();
  }

  getSite() {
    if (!this.state.addSite) {
      getSite(this.props.match.params.id, (status, response) => {
        if (status === "success") {
          this.setState({
            site: response.site,
            name: response.site.name,
            url: response.site.url,
          });
        }
      });
    } else {
      this.setState({
        site: this.state.addSite.site,
        name: this.state.addSite.site.name,
        url: this.state.addSite.site.url,
      });
    }
  }

  handleOnchange(name, value) {
    this.setState({
      [name]: value,
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    let url = this.state.url;
    let name = this.state.name;
    let error = false;
    let urlPattern = new RegExp(
      /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/g
    );
    console.log(url, name);
    if (name.length < 1) {
      this.setState({
        error_name: "Site name is requried",
      });
      error = true;
    } else {
      this.setState({
        error_name: undefined,
      });
    }

    if (!urlPattern.test(url)) {
      this.setState({
        error_url: "Please enter a correct url",
      });
      error = true;
    } else {
      this.setState({
        error_url: undefined,
      });
    }

    if (error) {
      return false;
    }

    let call = this.props.match.params.id
      ? updateSite.bind(null, this.props.match.params.id)
      : addSite;

    call(name, url, (status, response) => {
      console.log(status, response);
      if (status === "success") {
        console.log(response);
        localStorage.setItem("addSite", JSON.stringify(response));
        this.props.history.push(
          _.replace(
            Constants.ROUTES.ADD_SITE_WELCOME_NOTIF,
            /:id/g,
            response.site.id
          )
        );
      } else if (status === "validation_error") {
        // console.log(response);
        _.each(response, (index, error) => {
          let errorKey = "error_" + error;
          // console.log(errorKey, index);
          this.setState({
            [errorKey]: index.toString(),
          });
        });
      }
    });
  }

  componentDidMount() {
    this.setState({});
    getSiteHelper(this, this.props);
  }

  componentWillMount() {
    console.log(this.state.error_name);
  }

  componentWillUpdate(nextProps, nextState) {
    console.log(this.state.site, nextState.site);
    if (this.state.site !== nextState.site) {
      this.setState({
        site: nextState.site,
        name: nextState.site.name,
        url: nextState.site.url,
      });
    }

    // if (this.props.site !== nextProps.site) {
    //   this.setState({
    //     site: nextProps.site,
    //   });
    // }
  }

  render() {
    return (
      <AddSite
        history={this.props.history}
        pageTitle="Add Site"
        menuActive={Constants.ROUTES.ADD_SITE}
        bodyClass="dashboard profile-dash"
        {...this.props}
      >
        <h3 class="">Site Setup</h3>
        <br />
        <br />
        <form class="d-form d-form-1" action="site-welcome-notif.php">
          <div class="form-group">
            <label for="">Site Name</label>
            <Input
              elementonly="true"
              type="text"
              onChange={this.handleOnchange}
              class="form-control"
              name="name"
              placeholder="My Website"
              errortext={this.state.error_name}
              value={this.state.name}
            />
          </div>
          <div class="form-group">
            <label for="">Site URL</label>
            <Input
              elementonly="true"
              type="text"
              onChange={this.handleOnchange}
              class="form-control"
              name="url"
              placeholder="www.mywebsite.com"
              errortext={this.state.error_url}
              value={this.state.url}
            />
          </div>
          <div class="form-group">
            <input
              type="submit"
              class="btn d-btn"
              name=""
              onClick={this.handleSubmit}
              value="Next"
            />
          </div>
        </form>
      </AddSite>
    );
  }
}
