import axioss from "axios";
import _ from "lodash";
import axiosRetry from "axios-retry";

// const gToken = localStorage.getItem('tokenn');
let axios = refreshAxios();
let URL = 'https://learncbse.arthism.com/api/api'
// let URL = "http://localhost:8000/api";

function refreshAxios() {
  let ax = axioss.create({
    baseURL: URL,
    timeout: 10000,
    headers: {
      "Access-Control-Allow-Origin": "*",
    },
  });
  ax.defaults.headers.common["Authorization"] = "Bearer " + getToken();
  ax.defaults.headers.common["Content-Type"] =
    "application/x-www-form-urlencoded";
  axiosRetry(ax, {
    retries: 3,
  });

  return ax;
}

function getToken() {
  return localStorage.getItem("token");
}

function clearToken() {
  localStorage.removeItem("token");
}

function login(email, password, cb) {
  axios
    .post(URL + "/auth/login", {
      email: email,
      password: password,
    })
    .then((response) => {
      // if( response.data.user.role.indexOf('Analyst') >= 0
      // ){
      if (response.data.status === "error") {
        handleError(response, cb);
      } else {
        console.log(response);
        localStorage.removeItem("addSite");
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("roles", response.data.roles);
        localStorage.setItem("tname", response.data.data.name);
        localStorage.setItem("temail", response.data.data.email);
        localStorage.setItem("tid", response.data.data.id);

        axios = refreshAxios();
        handleResponse(response, cb);
      }

      // }else{
      //     let error = {
      //         response : {
      //             statusText : 'Unauthorized'
      //         }
      //     }
      //     handleError(error,cb);

      // }
    })
    .catch((error) => {
      handleError(error, cb);
    });
}

function refreshToken(cb, catchcb) {
  axios
    .post(URL + "/auth/refresh", {})
    .then((response) => {
      console.log(response);
      localStorage.setItem("token", response.data.token);
      cb();
    })
    .catch((error) => {
      catchcb(error);
    });
}

function logout(cb) {
  axios
    .post(URL + "/auth/logout", {})
    .then((response) => {
      handleResponse(response, cb);
    })
    .catch((error) => {
      handleError(error, cb);
    });
}

function reset(email, password_confirmation, password, cb) {
  axios
    .post(URL + "/auth/reset", {
      token: getToken(),
      email: email,
      password_confirmation: password_confirmation,
      password: password,
    })
    .then((response) => {
      handleResponse(response, cb);
    })
    .catch((error) => {
      handleError(error, cb);
    });
}

function addSite(site_name, site_url, cb) {
  axios
    .post(URL + "/site/add", {
      name: site_name,
      url: site_url,
    })
    .then((response) => {
      handleResponse(response, cb);
    })
    .catch((error) => {
      handleError(error, cb);
    });
}

function updateSite(id, site_name, site_url, cb) {
  axios
    .patch(URL + "/site/" + id, {
      name: site_name,
      url: site_url,
    })
    .then((response) => {
      handleResponse(response, cb);
    })
    .catch((error) => {
      handleError(error, cb);
    });
}

function deleteSite(id, cb) {
  axios
    .delete(URL + "/site/" + id)
    .then((response) => {
      handleResponse(response, cb);
    })
    .catch((error) => {
      handleError(error, cb);
    });
}

function addWelcomeNotification(id, title, message, cb) {
  axios
    .post(URL + "/welcome-notification/add", {
      site_id: id,
      title: title,
      message: message,
      active: 0,
    })
    .then((response) => {
      handleResponse(response, cb);
    })
    .catch((error) => {
      handleError(error, cb);
    });
}

function updateWelcomeNotification(id, title, message, cb) {
  axios
    .patch(URL + "/welcome-notification/" + id, {
      title: title,
      message: message,
    })
    .then((response) => {
      handleResponse(response, cb);
    })
    .catch((error) => {
      handleError(error, cb);
    });
}

function verifyPixel(id, cb) {
  axios
    .get(URL + "/site/" + id + "/verify-pixel", {})
    .then((response) => {
      handleResponse(response, cb);
    })
    .catch((error) => {
      handleError(error, cb);
    });
}

function getNotifications(siteId, cb) {
  axios
    .get(URL + "/notification/list/" + siteId, {})
    .then((response) => {
      handleResponse(response, cb);
    })
    .catch((error) => {
      handleError(error, cb);
    });
}

function getSites(cb) {
  axios
    .get(URL + "/site/list", {})
    .then((response) => {
      handleResponse(response, cb);
    })
    .catch((error) => {
      handleError(error, cb);
    });
}

function getSite(id, cb) {
  axios
    .get(URL + "/site/" + id, {})
    .then((response) => {
      handleResponse(response, cb);
    })
    .catch((error) => {
      handleError(error, cb);
    });
}

function sendNotification(site_id, title, message, obj, cb) {
  let formData = new FormData();
  formData.append("image", obj.image);
  formData.append("icon", obj.icon);
  formData.append("site_id", site_id);
  formData.append("title", title);
  formData.append("message", message);
  formData.append("launch_url", obj.launchURL);
  formData.append("send_later", obj.send_later);
  formData.append("send_date_time", obj.send_date_time);
  // {
  //     site_id: site_id,
  //     title: title,
  //     message: message,
  //     image: obj.image,
  //     launch_url: 'https://bitly.com',
  //     send_later: 0
  // }

  axios
    .post(URL + "/notification/add", formData, {
      headers: {
        "Content-Type": "multipart/form-data; charset=utf-8; boundary=" +
          Math.random()
          .toString()
          .substr(2),
      },
    })
    .then((response) => {
      handleResponse(response, cb);
    })
    .catch((error) => {
      handleError(error, cb);
    });
}

function handleResponse(response, cb) {
  console.log("resp", response);
  if (_.has(response, "statusText")) {
    // if ((response.status !== 200) || (response.status !== 201)) {
    //     //handle error
    //     if (_.has(response.data, 'errorData')) {
    //         if (_.has(response.data.errorData, 'errorCode') &&
    //             _.has(response.data.errorData, 'errorReason')) {
    //             cb('error', response.data.errorData.errorCode + ': ' + response.data.errorData.errorReason);

    //         }
    //     }
    // } else {
    // console.log("resp", response);
    //handle success
    if (_.has(response, "data")) {
      cb("success", response.data);
    }
    // }
  }
}

function handleError(error, cb) {
  console.log("err", error);
  console.log(error.response);
  const originalRequest = error.config;

  // console.log(_.has(error, 'message'));
  // console.log(_.has(error, 'response'));

  if (_.has(error, "response")) {
    if (_.has(error.response, "statusText")) {
      var errorMsg = error.response.statusText;
      switch (errorMsg) {
        case "Unauthorized":
          refreshToken(
            () => {
              axios = refreshAxios();
              originalRequest.headers["Authorization"] = "Bearer " + getToken();
              console.log(originalRequest);
              axios(originalRequest)
                .then((response) => {
                  handleResponse(response, cb);
                })
                .catch((error) => {
                  handleError(error, cb);
                });
              return;
            },
            (er) => {
              clearToken();
              window.location = "/";
              cb("error", "Invalid Token");
            }
          );
          break;
        case "Bad Request":
          cb("error", "Bad Request");
          break;
        case "Network Error":
          console.log(error.message);
          cb("error", "Couldn't connect to Internet");
          break;
        case "Forbidden":
          console.log(error.response.data.message);
          cb("error", error.response.data.message);
          break;
        case "Unprocessable Entity":
          cb("validation_error", error.response.data.error.errors);
          break;
        default:
          cb("error", "An error occured");
          break;
      }
    } else if (_.has(error, "message")) {
      console.log(error);
      var errorMsg = error.message;
      switch (errorMsg) {
        case "Network Error":
          console.log(error.message);
          cb("error", "Couldn't connect to Internet");
          break;

        default:
          cb("error", "An error occured");
          break;
      }
    }
  }
}

export {
  axios,
  getToken,
  refreshAxios,
  login,
  addSite,
  updateSite,
  deleteSite,
  addWelcomeNotification,
  updateWelcomeNotification,
  verifyPixel,
  getSites,
  sendNotification,
  getSite,
  getNotifications,
  logout,
  reset,
};