import React, { Component } from "react";
import Dashboard from "../../layouts/Dashboard";
import { Link } from "react-router-dom";
import Constants from "../../../Utilities/Constants";
import _ from "lodash";
import Input from "../../../components/Input";
import $ from "jquery";
import { getSites, deleteSite } from "../../../Axios/Axios";
import SiteCard from "../../../components/SiteCard";
import { getSiteHelper, getSitesHelper } from "../../../Utilities/Helper";

export default class DashboardHome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      siteList: [],
      activeSiteList: [],
      inactiveSiteList: [],
      activeSiteObj: "",
      inactiveSiteObj: "",
      totalSubscribers: "",
      blockUI: true,
    };
    this.getSites();
    this.handleBlockUI = this.handleBlockUI.bind(this);
  }

  handleBlockUI(val) {
    this.setState({
      blockUI: val,
    });
  }

  getSites() {
    this.handleBlockUI(true);
    getSites((status, response) => {
      if (status === "success") {
        console.log(response);
        let inactiveSites = _.concat(response.sites);
        let activeSites = _.concat(response.sites);
        _.pullAllBy(activeSites, [{ pixel_active: "0" }], "pixel_active");
        _.pullAllBy(inactiveSites, [{ pixel_active: "1" }], "pixel_active");
        console.log(inactiveSites);
        // console.log(response);
        this.setState(
          {
            siteList: response.sites,
            activeSiteList: activeSites,
            inactiveSiteList: inactiveSites,
            totalSubscribers: response.total_subscribers,
          },
          () => {
            this.renderSiteList(1);
            this.renderSiteList(2);
            this.handleBlockUI(false);
          }
        );
      }
    });
  }

  componentDidMount() {
    this.setState({});
  }

  renderSiteList(pixelActive) {
    switch (pixelActive) {
      case 1:
        console.log(this.state.activeSiteList);
        let activeSiteObj = this.state.activeSiteList
          ? _.map(this.state.activeSiteList, (site) => {
              return (
                <div class="col-md-6 col-lg-3">
                  <div class="">
                    <SiteCard site={site} refresh={() => this.getSites()} />
                  </div>
                </div>
              );
            })
          : "";
        console.log(activeSiteObj);
        this.setState({
          activeSiteObj: activeSiteObj,
        });
        return;
      case 2:
        console.log(this.state.inactiveSiteList);
        let inactiveSiteObj = this.state.inactiveSiteList
          ? _.map(this.state.inactiveSiteList, (site) => {
              return (
                <div class="col-md-6 col-lg-3">
                  <div class="">
                    <SiteCard
                      site={site}
                      style={{ background: "#d4d4d4" }}
                      buttonText="Complete Setup"
                      buttonLink={Constants.ROUTES.EDIT_SITE}
                      refresh={() => this.getSites()}
                      statusText="Pixel is not activated"
                    />
                  </div>
                </div>
              );
            })
          : "";
        console.log(inactiveSiteObj);
        this.setState({
          inactiveSiteObj: inactiveSiteObj,
        });
        return;
      default:
        return;
    }
  }

  componentWillUpdate(nextProps, nextState) {
    if (nextState.siteList && nextState.siteList !== this.state.siteList) {
      this.renderSiteList(1);
    }
  }

  render() {
    if (this.state.blockUI) {
      return <div className="ui-blocker loader" />;
    }

    return (
      <Dashboard
        {...this.props}
        match={this.props.match}
        history={this.props.history}
        pageTitle="Dashboard"
        menuActive={Constants.ROUTES.ADMIN_DASHBOARD}
        bodyClass="dashboard"
        defaultSite={this.state.activeSiteList[0]}
        allSites={this.state.activeSiteList}
      >
        <div class="stats-container-2">
          <div class="row">
            <div class="col-md-6 col-lg-3">
              <div class="d-stats-box l-d-stats-box">
                <h1 class="d-stats-box__count">
                  {this.state.activeSiteList.length}
                </h1>
                <p class="d-stats-box__text">Total Sites</p>
                <div class="d-stats-box__progress progress">
                  <div
                    class="progress-bar"
                    role="progressbar"
                    style={{ width: "25%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="d-stats-box l-d-stats-box">
                <h1 class="d-stats-box__count">
                  {this.state.totalSubscribers
                    ? this.state.totalSubscribers
                    : "-"}
                </h1>
                <p class="d-stats-box__text">Total Subscribers</p>
                <div class="d-stats-box__progress progress">
                  <div
                    class="progress-bar bg-success"
                    role="progressbar"
                    style={{ width: "56%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="d-stats-box l-d-stats-box">
                <h1 class="d-stats-box__count">-</h1>
                <p class="d-stats-box__text">Push Notification in last hour</p>
                <div class="d-stats-box__progress progress">
                  <div
                    class="progress-bar bg-info"
                    role="progressbar"
                    style={{ width: "68%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6 col-lg-3">
              <div class="d-stats-box l-d-stats-box">
                <h1 class="d-stats-box__count">-</h1>
                <p class="d-stats-box__text">Pending Notifications</p>
                <div class="d-stats-box__progress progress">
                  <div
                    class="progress-bar bg-danger"
                    role="progressbar"
                    style={{ width: "25%" }}
                    aria-valuenow="25"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="stats-container-2">
          <div class="row">{this.state.activeSiteObj}</div>
        </div>
        <div class="stats-container-2">
          <div class="row">
            <div class="d-box add-project-box">
              <h4>Inactive Sites</h4>
            </div>
            {this.state.inactiveSiteObj}
          </div>
        </div>
      </Dashboard>
    );
  }
}
