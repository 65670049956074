import React, { Component } from "react";

import Home from "./Home";
import Constants from "../Utilities/Constants";
import Login from "./site/login/Login";
import Logout from "./site/login/Logout";
// import Forgot from './site/forgot/Forgot';
import DashboardHome from "./admin/dashboard/DashboardHome";
import SiteSetup from "./admin/add-site/SiteSetup";
import WelcomeNotification from "./admin/add-site/WelcomeNotification";
import InstallPixel from "./admin/add-site/InstallPixel";
import VerifyPixel from "./admin/add-site/VerifyPixel";
import Send from "./admin/notification/Send";
import History from "./admin/notification/History";
import SiteStat from "./admin/dashboard/SiteStat";
import ChangePassword from "./site/login/ChangePassword";
import { getSiteHelper, getSitesHelper } from "../Utilities/Helper";

import { Route, Switch } from "react-router-dom";

class View extends Component {
  constructor(props) {
    super(props);
    this.getSite = this.getSite.bind(this);
    this.getSites = this.getSites.bind(this);
    this.state = {
      site: [],
      activeSites: [],
    };
  }
  getSite(props) {
    // getSiteHelper(this, props);
  }

  getSites(props) {
    // getSitesHelper(this, props);
  }

  componentWillUpdate(nextProps, nextState) {
    if (this.state.site !== nextState.site) {
      this.setState({
        site: nextState.site,
      });
    }
    if (this.state.activeSites !== nextState.activeSites) {
      this.setState({
        activeSites: nextState.activeSites,
      });
    }
  }
  componentDidMount() {
    // this.getSite(this.props);
  }
  render() {
    return (
      <div className="body">
        <Switch>
          <Route path="/" exact component={Login} />
          <Route path="/logout" exact component={Logout} />
          <Route path={Constants.ROUTES.LOGIN} exact component={Login} />
          {/* <Route path="/forgot" exact component={Forgot} /> */}
          <Route
            path={Constants.ROUTES.ADMIN_DASHBOARD}
            exact
            render={(props) => (
              <DashboardHome
                {...props}
                view={Constants.ROUTES.ADMIN_DASHBOARD}
                activeSites={this.state.activeSites}
                initSites={this.getSites(props)}
              />
            )}
          />
          <Route path={Constants.ROUTES.ADD_SITE} exact component={SiteSetup} />
          <Route
            path={Constants.ROUTES.EDIT_SITE}
            exact
            render={(props) => (
              <SiteSetup
                {...props}
                view={Constants.ROUTES.EDIT_SITE}
                site={this.state.site}
                initSite={this.getSite(props)}
              />
            )}
          />
          <Route
            path={Constants.ROUTES.ADD_SITE_WELCOME_NOTIF}
            exact
            render={(props) => (
              <WelcomeNotification
                {...props}
                view={Constants.ROUTES.EDIT_SITE}
                site={this.state.site}
                initSite={() => this.getSite(props)}
              />
            )}
          />
          <Route
            path={Constants.ROUTES.ADD_SITE_PIXEL_INSTALL}
            exact
            render={(props) => (
              <InstallPixel
                {...props}
                view={Constants.ROUTES.EDIT_SITE}
                site={this.state.site}
                initSite={this.getSite(props)}
              />
            )}
          />
          <Route
            path={Constants.ROUTES.ADD_SITE_PIXEL_VERIFY}
            exact
            render={(props) => (
              <VerifyPixel
                {...props}
                view={Constants.ROUTES.EDIT_SITE}
                site={this.state.site}
                initSite={this.getSite(props)}
              />
            )}
          />
          <Route
            path={Constants.ROUTES.SITE_SEND_PUSH}
            exact
            render={(props) => (
              <Send
                {...props}
                view={Constants.ROUTES.SITE_SEND_PUSH}
                site={this.state.site}
                defaultSite={this.state.site}
                activeSites={this.state.activeSites}
                initSite={this.getSite(props)}
                initSites={this.getSites(props)}
              />
            )}
          />
          <Route
            path={Constants.ROUTES.SITE_NOTIF_HISTORY}
            exact
            render={(props) => (
              <History
                {...props}
                view={Constants.ROUTES.SITE_NOTIF_HISTORY}
                site={this.state.site}
                defaultSite={this.state.site}
                activeSites={this.state.activeSites}
                initSite={this.getSite(props)}
                initSites={this.getSites(props)}
              />
            )}
          />
          <Route
            path={Constants.ROUTES.SITE_STAT}
            exact
            render={(props) => (
              <SiteStat
                {...props}
                view={Constants.ROUTES.SITE_STAT}
                site={this.state.site}
                defaultSite={this.state.site}
                activeSites={this.state.activeSites}
                initSite={this.getSite(props)}
                initSites={this.getSites(props)}
              />
            )}
          />
          <Route
            path={Constants.ROUTES.USER_CHANGE_PASSWORD}
            exact
            component={ChangePassword}
          />
          <Route path={Constants.ROUTES.LOGOUT} exact component={Logout} />
          {/* <Route
            exact
            path="/details/:id"
            render={(props) => {
              <DetailsPage id={props.match.params.id} />;
            }}
          /> */}
        </Switch>
      </div>
    );
  }
}

export default View;
